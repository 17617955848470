import * as Yup from 'yup';
import React, { Component }  from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import loading from './loading.gif'; // with import
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
// import Hidden from '@mui/core';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Hidden,
  Paper
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import Button from 'src/theme/overrides/Button';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function VerifyPhoneForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');
  const [buttonText, setButtonText] = useState('Call Me Now to Verify Phone Number');
  const [buttonTextState, setButtonTextState] = useState('');
  const [displayVerifyToken, setDisplayVerifyToke] = useState(true);
  const [securityCode, setSecurityCode] = useState('');
  const [ajaxAction, setAjaxAction] = useState(1);
  const [showRetry, setShowRetry] = useState(true);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    // phone: Yup.string().required('Phone Number is required'),
    password: Yup.string().required('Password is required')
  });

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  const { state } = useLocation();
  // console.log(state);
  if (state == null) {
    navigate('/', { replace: true });
  }
  const defaultMobile = state.realMobile;
  const defaultLang = state.lang;
  console.log(state)
  const mobilePhones = defaultMobile
  const realMobile = defaultMobile;
  const nextStep = state.url;
  // console.log(defaultMobile);

  const toggleCall = (defaultMobile, lang) => {
    console.log('togu ni')
    setAjaxAction(0)
    if (buttonText === 'Call Me Now to Verify Phone Number') {
      console.log('jkasci call me ni')
      callMe(defaultMobile, lang);
    } else if (buttonText === 'Verify') {
      confirmToken(securityCode);
    }
  };

  async function callMe(mobilePhones, lang) {
    console.log(lang)
    console.log('salah')
    setButtonTextState('disabled');

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      language: lang,
      mobilePhone: mobilePhones
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/verifymobilephone', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // return response;
        if (response.status === '200') {
          setAjaxAction(1)
          setButtonTextState('');
          setSecurityCode(response.data+"#");
          setDisplayVerifyToke(false);
          setButtonText('Verify');
          const timer = setTimeout(() => {
            setShowRetry(false);
          }, 10000);
        } else {
          setAjaxAction(1)
          setOpen(false)
          var msg = 'Error, try again later';
          Swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
        
      })
      .catch(function(err) {
        console.log(err);
        setAjaxAction(1)
        setOpen(false)
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    // return pp;
  }

  async function register() {
    const userDetails = sessionStorage.getItem('pinspayCreatUser');
    setButtonTextState('disabled');

    var rxq = localStorage.getItem('rxq');
    var req = userDetails

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();
    
    const pp = await fetch(Config.base_url+'/createuser', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // return response;
        if (response.status === '200') {
          const createpwd = createPassword();
        } else {
          // alert(response.message);
          setOpen(true)
          setAjaxAction(1)
          handleClose()
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    // return pp;
  }

  async function createPassword() {
    const pinspayPwd = JSON.parse(sessionStorage.getItem('pinspayPwd'));
    pinspayPwd.reference = securityCode
    console.log(pinspayPwd);
    setButtonTextState('disabled');

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify(pinspayPwd)

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/createpassword', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.status + ' ' + response.message);
        const pinsJson = pinspayPwd;
        const password = pinsJson.password
        // return response;
        // Login
        if (response.status == '200') {
          console.log('redirect to continue')
          handleClose()
          if(pinsJson.purpose == 'forgetpassword'){
            Swal.fire({
              title: 'Successful!',
              text: "Your password was changed successfully",
              icon: 'success',
              confirmButtonText: 'Login'
            }).then((resp)=>{
              navigate('/login', { state: { mobilePhones } });
            })
            
          }else{
            navigate('/registercont', { state: { mobilePhones, password } });
          }
          
          // const login = callLogin(defaultMobile, pinsJson.password);
          
        } else {
          // alert(response.message);
          setOpen(false)
          setAjaxAction(1)
          handleClose()
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      })
      .catch(function(err) {
        handleClose()
        console.log(err);
        var msg = "Technical error. Please try again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    // return pp;
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async function forgetPassword(defaultMobile) {
    const pinspayPwd = sessionStorage.getItem('pinspayPwd');
    setButtonTextState('disabled');
    // alert('You are about to reset your password');
    // const pp = await fetch('https://pinspay.com/api/createpassword', {
    //   method: 'POST',
    //   headers: {
    //     'Content-type': 'application/json'
    //   },
    //   body: pinspayPwd
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     console.log(response);
    //     const pinsJson = JSON.parse(pinspayPwd);
    //     if (response.status === '200') {
    //       const login = callLogin(defaultMobile, pinsJson.password);
    //     }
    //   });
    // return pp;
  }

  async function callLogin(mobilePhones, userPassword) {
    // await sleep(5000)
    // var cipherPwd = CryptoJS.AES.encrypt(userPassword, 'secret key kpngz@!1234').toString();

    // var cipherPwd = CryptoJS.AES.encrypt(userPassword, 'secret key kpngz@!1234').toString();


    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      password: userPassword
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/login', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === '200') {
          const loginToken = response.data._token;
          global.loginToken = loginToken;
          global.isLogin = true;
          // console.log(loginToken);
          sessionStorage.setItem('pinspay_record', JSON.stringify(response.data.record))
          sessionStorage.setItem('pinspayFetchUsers', JSON.stringify(response.data.user))
          sessionStorage.setItem('pinspaySuccessfulTransaction', response.data.successfulTransaction)
          sessionStorage.setItem('pinspayLoginToken', loginToken);

          sessionStorage.setItem('pinspayUnclaimed', response.data.unclaimed)
          if(response.data.unclaimed == true) {
            sessionStorage.setItem('pinspayUnclaimedData', JSON.stringify(response.data.unclaimed_data))
          }
          navigate('/dashboard', { replace: true });
        } else {
          // alert(response.message);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          setAjaxAction(1)
        }
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        setAjaxAction(1)
    })
    return pp;
  }

  async function confirmToken(securityCode) {
    setAjaxAction(0)
    setButtonTextState('disabled');
    setOpen(true)

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      verify_token: securityCode,
      mobilePhone: defaultMobile
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/checkstatus', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // return response;
        // setButtonTextState('');
        // setSecurityCode(response.data);
        setAjaxAction(1)
        setOpen(true)
        

        if (response.message === 'success') {
          // Register api
          if (nextStep === 'register') {
            register();
          } else if (nextStep === 'createpassword') {
            createPassword();
          } else if (nextStep === 'forgetpassword') {
            forgetPassword();
          }
        } else if (response.message === 'pending') {
          setAjaxAction(1)
          setOpen(false)
          var msg = 'Token verification is still pending. Please try again';
          Swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return;
        } else if (response.message === 'Failed') {
          setAjaxAction(1)
          setOpen(false)
          var msg = 'Token verified failed.';
          Swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        } else {
          // alert(response.message)
          setAjaxAction(1)
          setOpen(false)
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      })
      .catch(function(err) {
        console.log(err);
        setOpen(false)
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    // return pp;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      const mobilePhones = defaultMobile;
      const userPassword = values.password;

      // const check = await callOut(mobilePhones, userPassword);
      // console.log(check.message);
      // if (check.status === '200') {
      //   const loginToken = check.data._token;
      //   global.loginToken = loginToken;
      //   global.isLogin = true;
      //   // console.log(loginToken);
      //   sessionStorage.setItem('pinspayLoginToken', loginToken);
      //   navigate('/dashboard', { replace: true });
      // }
      // if (check.status === '200') {
      //   sessionStorage.setItem('phoneNumber', mobilePhones);
      //   navigate('/login', { replace: true });
      // }
      // if (check.status === '400') {
      //   navigate('/register', { replace: true });
      // }
      // navigate('/dashboard', { replace: true });
    }
  });

  const phoneNumber = global.mobilePhone;
  const isPhone = true;
  // alert(phoneNumber);
  if (phoneNumber === '' || phoneNumber === null) {
    // isPhone = false;
    // navigate('/phone', { replace: true });
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // console.log(defaultMobile);
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              // autoComplete="Phone"
              value={defaultMobile}
              disabled={isPhone}
              // {...(isPhone ? 'disabled="true"' : 'disabled="true"')}
              label="Phone Number"
            />

            <Paper sx={displayVerifyToken && { display: 'none' }}>
              <TextField fullWidth label="Security Code" disabled value={securityCode} />
              Enter code in the call prompt
            </Paper>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            /> */}

            {/* <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
              Forgot password?
            </Link> */}
          </Stack>

          <LoadingButton
            fullWidth
            // disabled={(ajaxAction) ? false : true}
            size="large"
            type="button"
            onClick={() => toggleCall(defaultMobile, defaultLang)}
            variant="contained"
            // loading={isSubmitting}
            loading={(ajaxAction) ? false : true}
          >
            {buttonText}
          </LoadingButton>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            /> */}

            <Link component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
              Cancel
            </Link> 
            <Paper sx={showRetry && { display: 'none' }} className="MuiTypography-root MuiTypography-subtitle2 MuiLink-root MuiLink-underlineAlways css-f9272k-MuiTypography-root-MuiLink-root" style={{cursor:'pointer'}} onClick={() => window.location.reload()}>
              Call me again
            </Paper>
          </Stack>
        </Form>
      </FormikProvider>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={style} style={{borderRadius: 12, padding:10}}>
          <img alt="Pinspay Logo" src={loading} width="50px" style={{resizeMode: 'contain',alignSelf: 'center'}} />
              
          </Box>
      </Modal>
    </>
  );
}




