import React, { Component }  from 'react';
const APP_NAME = 'Pinspay PWA';

const BASE_URL = 'https://pinspay.com/api/pwa';

// const BASE_URL = Platform.select({
//   android: 'http://10.0.2.2:8000/api',
//   ios: 'http://127.0.0.1:8000/api',
// });

const Config = {
  base_url: BASE_URL,
  appName: APP_NAME,
};

export default Config;
