import React, { Component }  from 'react';
import PropTypes from 'prop-types';
// import React, { Component }  from 'react';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  // return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
  return <img alt="Pinspay Logo" src="https://pinspay.com/assets/images/pinspay_logo.png" width="150px" />;
}
