import * as Yup from 'yup';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Button from '@mui/material/Button';
// material
import {
  ListSubheader,
  TextField,
  InputAdornment,
  Stack, IconButton, Input
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
import { min } from 'lodash';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  console.log(containsText);

export default function Customeauth() {
  const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [serverMsg, setServerMsg] = React.useState('none');
  const [transactionStatus, setTransactionStatus] = React.useState('Pending');
  const [currentCount, setCount] = useState(100);
  const [transferReference, setTransferReference] = React.useState('');
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }



  const [searchText, setSearchText] = useState("");
  // console.log(searchText)


  let scopen = sessionStorage.getItem('pinspayFetchUsers');
  let defBal = 0;
  if(scopen != null){
    scopen = JSON.parse(scopen);
    defBal = scopen.AvailableBalance
  }

  const [accountBalance, setAccountBalance] = useState(defBal);
  // console.log(defBal);

  let ric = 100;


  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  const mobilePhone = userDetails.mobilePhone

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }
  var rxq = localStorage.getItem('rxq');

  async function callOut(mobilePhones, code, pin) {
    
    console.log(pin)

    let piks = CryptoJS.AES.encrypt(pin,'iuwdhuieb').toString();
    
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      code: code,
      pin: pin
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();
    console.log(cipherRxq)
    const pp = await fetch(Config.base_url+'/customcode', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    return pp;
  }

  const RegisterSchema = Yup.object().shape({
    code: Yup.string().min(2, 'Too Short. 2 digits required.').max(2, 'Too Long. 2 digits required.').required('Code is required'),
    pin: Yup.string().min(4, 'Too Short. 4 digits required.').max(4, 'Too Long. 4 digits required.').required('Pin is required')
  });

  const defaultMobile = mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  let myInterval = '';
  let referenceP = ''

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const formik = useFormik({
    initialValues: {
      pin: '',
      recipient: '',
      description: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      const code = values.code;
      const pin = values.pin;
      const mobilePhones = defaultMobile;
      console.log(pin)
      const check = await callOut(mobilePhones, code, pin);

      if (check.status === '200') {
        Swal.fire({
          title: 'Success!',
          text: check.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }else if (check.status === '900'){
        // alert(response.message);
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: '' });
      } else {
        // alert();
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };


  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  


  return (
    <>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            

            <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>

              <TextField
                type="number"
                size='large'
                InputProps={{
                    maxLength: 2,
                    endAdornment: <InputAdornment position="end"><Typography style={{fontSize:'30px'}}>#</Typography></InputAdornment> 
                  }}
                label="Code"
                {...getFieldProps('code')}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />

              <TextField
                size="large"
                label="Pin"
                onInput={checkPin}
                label="Transaction Pin"
                inputProps={{ maxLength: 4 }}
                {...getFieldProps('pin')}
                error={Boolean(touched.pin && errors.pin)}
                helperText={touched.pin && errors.pin}
              />
             
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
        </Form>
        </FormikProvider>
    </>
  );
}
