import * as Yup from 'yup';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [languages, setLang] = useState('');
  const [genders, setGender] = useState('');
  const [buttonTextState, setButtonTextState] = useState('');
  const [dobs, setDob] = useState(null);
  const [ajaxAction, setAjaxAction] = useState(1);

  const handleChange = (event) => {
    setLang(event.target.value);
    // console.log(event.target.value);
  };

  const handleGender = (event) => {
    setGender(event.target.value);
    // console.log(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
    bvn: Yup.string().min(11, 'Too Short!').max(11, 'Too Long!'),
    pin: Yup.string()
      .min(3, 'Too Short!')
      .max(4, 'Too Long!')
      .required('Password is required'),
    c_pin: Yup.string().oneOf([Yup.ref('pin'), null], 'Password must match')
  });

  const { state } = useLocation();
  console.log(useLocation());
  if (useLocation().state === null) {
    
    window.location.href = './'
  }

  const defaultMobile = state.mobilePhones;
  const userPassword = state.password;
  const mobilePhones = defaultMobile
  const realMobile = defaultMobile;
  console.log(userPassword)

  const formik = useFormik({
    initialValues: {
      email: '',
      bvn: '',
      pin: '',
      c_pin: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const realDate = Intl.DateTimeFormat('en-UK', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(dobs);
      console.log(values);
    //   values.password = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
    //   values.c_password = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
      values.pin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
      values.c_pin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
      const allValues = { ...values, dob: realDate, mobilePhone: realMobile };
      
      console.log(allValues);
      // sessionStorage.setItem('pinspayCreatUser', JSON.stringify(allValues));
      // sessionStorage.setItem('pinspayPwd', JSON.stringify(passwordArray));
      // navigate('/verifyphone', { state: { realMobile, url: 'register' } });
      // navigate('/dashboard', { replace: true });
      register(allValues);
    }
  });

  async function register(values) {
    console.log(values)
    // return;
    const userDetails = JSON.stringify(values);
    setButtonTextState('disabled');
    var rxq = localStorage.getItem('rxq');
    
    var req = userDetails

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/registerforwallet', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel': 'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // return response;
        if (response.status === '200') {
          // const createpwd = createPassword();
          callLogin(mobilePhones, userPassword)
        } else {
          // alert(response.message);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    // return pp;
  }
  

  async function callLogin(mobilePhones, userPassword) {
    // await sleep(5000)
    // var cipherPwd = CryptoJS.AES.encrypt(userPassword, 'secret key kpngz@!1234').toString();

    // var cipherPwd = CryptoJS.AES.encrypt(userPassword, 'secret key kpngz@!1234').toString();


    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      password: userPassword
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/login', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === '200') {
          const loginToken = response.data._token;
          global.loginToken = loginToken;
          global.isLogin = true;
          // console.log(loginToken);
          sessionStorage.setItem('pinspay_record', JSON.stringify(response.data.record))
          sessionStorage.setItem('pinspayFetchUsers', JSON.stringify(response.data.user))
          sessionStorage.setItem('pinspaySuccessfulTransaction', response.data.successfulTransaction)
          sessionStorage.setItem('pinspayLoginToken', loginToken);

          sessionStorage.setItem('pinspayUnclaimed', response.data.unclaimed)
          if(response.data.unclaimed == true) {
            sessionStorage.setItem('pinspayUnclaimedData', JSON.stringify(response.data.unclaimed_data))
          }
          navigate('/dashboard', { replace: true });
        } else {
          // alert(response.message);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          setAjaxAction(1)
        }
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        setAjaxAction(1)
    })
    return pp;
  }

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  const checkName = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[^A-Za-z]+/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  

  const [dValue, setdValue] = useState(null);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
        <TextField
                fullWidth
                autoComplete="email"
                style={{marginTop:20,marginBottom:10}}
                // onInput={checkEmail}
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                style={{marginTop:10,marginBottom:10}}
                autoComplete="bvn"
                // onInput={checkBvn}
                type="text"
                label="BVN (Optional)"
                {...getFieldProps('bvn')}
                error={Boolean(touched.bvn && errors.bvn)}
                helperText={touched.bvn && errors.bvn}
              />
            {/* </Stack> */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} style={{marginTop:10,marginBottom:20}}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                onInput={checkPin}
                inputProps={{ maxLength: 4 }}
                label="Transaction Pin"
                {...getFieldProps('pin')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.pin && errors.pin)}
                helperText={touched.pin && errors.pin}
              />

              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Confirm Pin"
                onInput={checkPin}
                inputProps={{ maxLength: 4 }}
                {...getFieldProps('c_pin')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.c_pin && errors.c_pin)}
                helperText={touched.c_pin && errors.c_pin}
              />

              
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField fullWidth label="Phone Number" disabled value={defaultMobile} />

            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                label="Date of Birth"
                value={dobs}
                onChange={(newValue) => {
                  setDob(newValue);
                  console.log(newValue);
                }}
                // {...getFieldProps('dob')}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go home
          </Link> */}
          <RouterLink component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go Back
          </RouterLink> 
        </Stack>
      </Form>
    </FormikProvider>
  );
}
