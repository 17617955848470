import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, Button } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
// import { LoginForm } from '../components/authentication/login';
import { VerifyPhone } from '../components/authentication/phone';
import AuthSocial from '../components/authentication/AuthSocial';
// import Logo from 'src/components/Logo';



// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Account Validation | Pinspay">
      <MHidden width="mdDown">
      <AuthLayout>
        {/* Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link> */}
      </AuthLayout>

      
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome!
          </Typography>
          {/* <button
            type="button"
            className="MuiButton-contained MuiButton-containedPrimary MuiButtonBase-root css-18g5y1j-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root"
            style={{width:200, backgroundColor:'#0098DA', marginLeft:30}}
          >
            Back to Main Website
          </button> */}

          <Button
            style={{width:200, marginLeft:30}}
            variant="outlined"
            size="large"
            href='https://pinspay.com'
            onClick={() => {
            }}
          >
            Back to Main Website
          </Button>
          {/* <img src="/static/illustrations/illustration_login.png" alt="login" /> */}
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
      <MHidden width="smUp">
      <Typography style={{marginBottom:50}}>
              
            </Typography>
      </MHidden>
        <ContentStyle>
 
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Login / Register
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          {/* <AuthSocial /> */}

          <VerifyPhone style={{marginBottom:20}} />
          
          <Typography style={{textAlign:'right', bottom: 50, position:'absolute', right:10}}>
            <a href="https://pinspay.com" style={{color:'#0098DA', fontWeight:700}}>Main Website</a>
          </Typography>
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {/* Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link> */}
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
