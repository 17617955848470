import * as Yup from 'yup';
import React, { Component }  from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import CryptoJS from 'crypto-js';
import Config from '../../Helper/Config';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPin, setShowPin] = useState(false);
  

  const LoginSchema = Yup.object().shape({
    pin: Yup.string().required('Pin is required').min(4, 'Too Short!').max(4, 'Too Long!'),
    password: Yup.string().required('Password is required'),
    npassword: Yup.string().required('New Password is required'),
    c_npassword: Yup.string().oneOf([Yup.ref('npassword'), null], 'Password must match')
  });
 
  const pinspay_record = JSON.parse(sessionStorage.getItem('pinspay_record'));
  // console.log(pinspay_record)
  const defaultMobile = pinspay_record.mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  const realMobile = defaultMobile;
  // console.log(defaultMobile);

  async function changePassword(values) {
    var pinq = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
    var currPass = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
    var newPass = CryptoJS.AES.encrypt(values.npassword, 'secret key kpngz@!1234').toString();

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      pin: pinq,
      currentPassword: currPass,
      newPassword: newPass,
      m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/changepassword', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch(function(err) {
        console.log(err);
        alert("Technical error. Please again later");
    })
    return pp;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      c_password: '',
      pin: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      console.log(values);
      const check = await changePassword(values);
      if (check.status === '200') {
        Swal.fire({
          title: 'Sucess!',
          text: check.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        navigate('/dashboard', { state: { realMobile } });
      } else if(check.status === '300'){
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      } else if (check.status === '900'){
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        sessionStorage.removeItem("pinspayFetchUsers");
        sessionStorage.removeItem("pinspay_record");
        sessionStorage.removeItem("pinspayLoginToken");
        sessionStorage.removeItem("pinspaySuccessfulTransaction");
        navigate('/phone', { state: { realMobile } });
      } else {
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const phoneNumber = global.mobilePhone;
  const isPhone = true;
  // alert(phoneNumber);
  if (phoneNumber === '' || phoneNumber === null) {
    // isPhone = false;
    // navigate('/phone', { replace: true });
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPin = () => {
    setShowPin((show) => !show);
  };

  // console.log(defaultMobile);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={5}>
          <TextField
            fullWidth
            label="Pin"
            type={showPin ? 'text' : 'password'}
            {...getFieldProps('pin')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPin} edge="end">
                    <Icon icon={showPin ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.pin && errors.pin)}
            helperText={touched.pin && errors.pin}
          />

          <TextField
            fullWidth
            // autoComplete="Phone"
            type={showPassword ? 'text' : 'password'}
            label="Current Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('npassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.npassword && errors.npassword)}
            helperText={touched.npassword && errors.npassword}
          />

            <TextField
                fullWidth
                autoComplete="password"
                type={showPassword ? 'text' : 'password'}
                label="Confirm New Password"
                {...getFieldProps('c_npassword')}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                    </InputAdornment>
                )
                }}
                error={Boolean(touched.c_npassword && errors.c_npassword)}
                helperText={touched.c_npassword && errors.c_npassword}
            />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Continue
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
