import { useState, useEffect, useRef, useMemo } from 'react';
import React, { Component }  from 'react';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import AccountBalanceWallet from '@iconify/icons-ic/account-balance-wallet';
import RefreshBal from '@iconify/icons-ic/refresh';
import EyeOpen from '@iconify/icons-ant-design/eye-fill';
import EyeClose from '@iconify/icons-ant-design/eye-invisible-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import Config from '../../Helper/Config';
import CryptoJS from 'crypto-js';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function AppWeeklySales({ accountBalance }) {
  const [accountBalances, setAccountBalance] = useState(accountBalance);
  const [refreshTxt, setRefreshTxt] = useState('Account Balance');
  let act = parseFloat(accountBalances).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const [finalAcct, setFinalAcct] = useState(act);
  const [eyeState, setEyeState] = useState(EyeOpen);
  const [fbFtn, setFbFtn] = useState(false);

  const loginToken = sessionStorage.getItem('pinspayLoginToken');

  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  const mobilePhone = userDetails.mobilePhone

  async function fetchBal(mobilePhones) {
    if (fbFtn == true) {
      return;
    }
    setFbFtn(true);
    setRefreshTxt('Refreshing...');
    // setFinalAcct('0.00');
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      pwa_token: loginToken
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/checkbal', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === '200') {
          // alert(response.data)
          let act = parseFloat(response.data).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          setAccountBalance(act);
          setFinalAcct(act);
          setRefreshTxt('Account Balance');
          setFbFtn(false);
          return response;
        } else if (response.status === '900'){
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
          // alert(response.message);
          // navigate('/phone', { state: { mobilePhones } });
          window.location.href= '/phone';
          // setFbFtn(false)
        } else {
          setRefreshTxt('Account Balance');
          setAccountBalance(accountBalance);
          setFbFtn(false)
        }
      })
      .catch(function(err) {
        // console.log(err);
        Swal.fire({
          title: 'Error!',
          text: 'Technical error. Try again later',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setRefreshTxt('Account Balance');
        setAccountBalance(accountBalance);
        setFbFtn(false)
    })
    return pp;
  }

  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };

  function toggleEye(){
    if (eyeState == EyeClose) {
      setEyeState(EyeOpen);
      // alert(accountBalances)
      let act = parseFloat(accountBalances);
      setFinalAcct(act);
    } else if (eyeState == EyeOpen) {
      setEyeState(EyeClose);
      setFinalAcct('*****');
    }  
  }

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={AccountBalanceWallet} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">
        ₦ {finalAcct}     
        <Icon icon={eyeState} width={20} height={20} onClick={() => toggleEye()}/>
        </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {refreshTxt} <Icon icon={RefreshBal} width={20} height={20} onClick={() => fetchBal(mobilePhone)}/>
      </Typography>
    </RootStyle>
  );
}
