import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Modal from '@mui/material/Modal';
import enLocale from 'date-fns/locale/en-US';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import pinspayLogo from './pinspay_logo.png'; // with import
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
import Logo from '../../Logo';
import CryptoJS from 'crypto-js';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
  } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { split } from 'lodash';
import { date } from 'yup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(trans_id, amount, channel, c_d, desc, trans_date, balance) {
  return { trans_id, amount, channel, c_d, desc, trans_date, balance };
}

const humanDate=(udate)=>{
    var d = new Date(udate)
    return d.toUTCString();
}

// var tr = new Date();
// var tr = new Date("3/7/2022 2:39:33 PM")
// console.log(humanDate(tr))

export default function Statement() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowss, setRowss] = useState([]);
  const [loading, setLoading] = useState('Loading...');
  const [checkButton, setCheckButton] = useState(false);
  const [open, setOpen] = useState(false);

  const [amount, setAmount] = useState(0);
  const [bank, setBank] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [credit_account, setCreditAccount] = useState('');
  const [debit_account, setDebitAccount] = useState('');
  const [description, setDescription] = useState('');
  const [transfer_date, setTransferDate] = useState('');
  const [message, setMessage] = useState('');
  const [transfer_type, setTransferType] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showReciept, setShowReciept] = useState(true);

  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  // const mobilePhone = userDetails.mobilePhone
  const defaultMobile = userDetails.mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAmount(0)
      setBank('')
      setBeneficiary('')
      setCreditAccount('')
      setDebitAccount('')
      setDescription('')
      setMessage('')
      setTransferDate('')
      setTransferType('')
      setOpen(false)
    }
  }

    function formatMyDate(idate){
        console.log(idate)
        var dd = String(idate.getDate()).padStart(2, '0');
        var mm = String(idate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = idate.getFullYear();
        idate = mm + '/' + dd + '/' + yyyy;
        return idate;
    }


    
    var today = new Date();
    let end_de = new Date().toLocaleDateString('en-us') 
    let end_d = dateFormat(end_de, "MM/dd/yyyy")
    console.log('end date',end_d)

    let days = 86400000 //number of milliseconds in a day
    let start_de = new Date(today - (7*days)).toLocaleDateString('en-us')
    let start_d = dateFormat(start_de, "MM/dd/yyyy")
    console.log('start date',start_d)

    const [dateValue, setDateValue] = React.useState([start_d, end_d]);
    // const [dateValue, setDateValue] = React.useState(['03/14/2022', '03/21/2022']);
    console.log(dateValue)

  const rows = rowss.sort((a, b) => (a.TranID < b.TranID ? 1 : -1));

  async function getReceipt(arg){
    console.log(arg)
    Swal.fire({
      text: "Loading. Please Wait",
      timerProgressBar: true,
      showConfirmButton: false,
    })
    // alert(arg)
    var myArray = arg.split("/");
    console.log(myArray)
    
    var start_date = myArray[0]
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      requestId: arg
    })
    console.log(arg)

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    var receipt = await fetch(Config.base_url+"/transaction-reciept", {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'pwatoken': loginToken,
          'channel':'WEB'
        },
        body: JSON.stringify({
          rqt: cipherRxq,
          channel: 'WEB'
        })
    })
    .then((response) => response.json())
    .then((response) => {
      if(response.status == '200'){
        var dataRes = response.data.Status
        var realRes = ""
        if(dataRes == 2){
          var realRes = "Failed"
        }else if(dataRes == 1){
          var realRes = "Successful"
        }
        
        Swal.close()
        setOpen(true)
        setAmount(response.data.Amount)
        setBank(response.data.Bank)
        setBeneficiary(response.data.Beneficiary)
        setCreditAccount(response.data.Credit_account)
        setDebitAccount(response.data.Debit_account)
        setDescription(response.data.Description)
        setMessage(realRes)
        var d = new Date(response.data.Transaction_date);
        console.log(d.toUTCString());
        setTransferDate(d.toUTCString())
        setTransferType(response.data.Transaction_type)
      } else if(response.status == '900'){
        // setOpen(false)
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: { defaultMobile } });
      } else {
        Swal.close()
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    })
    .catch(function(err) {
      console.log(err);
      // alert("Technical error. Please again later");
    })

  }

  const _exportPdf = () => {

    html2canvas(document.querySelector("#capture"),{allowTaint:true,useCORS:true}).then(canvas => {
      //  document.body.appendChild(canvas);  // if you want see your screenshot in body.
       const imgData = canvas.toDataURL('image/png');
       const pdf = new jsPDF("p", "mm", "a4");
       var width = pdf.internal.pageSize.getWidth();
       var height = pdf.internal.pageSize.getHeight();
       pdf.addImage(imgData, 'PNG', 0, 0, width, height);
       pdf.save("receipt.pdf"); 
   });

}

  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };

  const fetchTransactions1 = async () => {
    setLoading('Loading...')
    
    let start_date = dateValue[0]
    let end_date = dateValue[1]
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      startDate: start_date,
      endDate: end_date
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const res = await fetch(Config.base_url+"/ministatement", {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'pwatoken': loginToken,
          'channel':'WEB'
        },
        body: JSON.stringify({
          rqt: cipherRxq,
          channel: 'WEB'
        })
    })

    .then((response) => response.json())
    .then((response) => {
      if(response.status == '200'){
        console.log(response)
        
        if (response.data.length > 0) {
          setLoading('')
        } else {
          setLoading('No Data')
        }
        setRowss(response.data)
        console.log(rowss)
      } else if(response.status == '900'){
        // setOpen(false)
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: { defaultMobile } });
      } else {
        console.log(response)
        setLoading('No data')
        setRowss([])
        // console.log(rowss)
      }
    })
    .catch(function(err) {
      console.log(err);
      // alert("Technical error. Please again later");
    })
    
    // const response = await res.json();
    // console.log(response.data);
    // localStorage.setItem('pinspay_banks', JSON.stringify(response.data));

  }


  function dateFormat(inputDate, format, pi=0) {
    console.log(inputDate)
   
    let nn = split(inputDate,"/");

    //extract the parts of the date
    let day = nn[1];
    let month = nn[0];
    let year = nn[2];
    // console.log('month', month)
    if(day.length == 1)
    {
      console.log("new", day)
      day = "0"+ day;
      console.log("new", day)
    }
    if(month.length == 1)
    {
      console.log("new", month)
      month = "0"+ month;
      console.log("neww", month)
    }
    console.log("newer", month+"/"+day+"/"+year)
    return month+"/"+day+"/"+year;

  }


  useEffect(() => {
    // setDateValue([start_d, end_d])
    
      
    const fetchTransactions = async () => {
      console.log(loginToken);
      let start_date = dateValue[0]
      let end_date = dateValue[1]
      // console.log(start_date, end_date)
      setLoading('Loading...')
      var rxq = localStorage.getItem('rxq');
    
      var req = JSON.stringify({
        startDate: start_date,
            endDate: end_date
      })

      var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();
      const res = await fetch(Config.base_url+"/ministatement", {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'pwatoken': loginToken,
            'channel':'WEB'
          },
          body: JSON.stringify({
            rqt: cipherRxq,
        channel: 'WEB'
          })
      })
  
      .then((response) => response.json())
      .then((response) => {
        if(response.status == '200'){
          console.log(response)
          if (response.data.length > 0) {
            setLoading('')
          } else {
            setLoading('No Data')
          }
          setRowss(response.data)
          console.log(rowss)
        } else if(response.status == '900'){
          // setOpen(false)
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
          navigate('/phone', { state: { defaultMobile } });
        } else {
          console.log(response)
          setLoading('No Data')
          setRowss([])
          // console.log(rowss)
        }
        
      })
      .catch(function(err) {
        console.log(err);
        setRowss([])
        // alert("Technical error. Please again later");
      })
    }
    
    fetchTransactions()
  },[])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Transactions
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
          
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale} style={{display:'inlineFlex'}}>
        
            <DateRangePicker
                style={{display:'inline-flex'}}
                startText="Start Date"
                endText="End Date"
                value={dateValue}
                mask="__/__/____"
                onChange={(newDateValue) => {
                    // console.log(newDateValue)
                    setDateValue(newDateValue);
                }}
                renderInput={(startProps, endProps) => (
                <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                </React.Fragment>
                )}
            />
            <Button
              style={{paddingLeft:'120'}}
              variant="outlined"
              size="large"
              onClick={() => {
                fetchTransactions1();
              }}
            >
              filter
            </Button>
        </LocalizationProvider>
        
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
            <TableRow>
                <TableCell>Trans ID</TableCell>
                <TableCell >Amount</TableCell>
                <TableCell >Channel</TableCell>
                <TableCell >Type</TableCell>
                <TableCell >Description</TableCell>
                <TableCell >Status</TableCell>
                <TableCell >Trans Date</TableCell>
                <TableCell >Balance</TableCell>
                <TableCell >Action</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
            ).map((row) => (
                <TableRow key={row.TranID}>
                  <TableCell component="th" scope="row">
                      {row.TranID}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.Amount}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.Channel}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {(row.DebitCredit == 'D')? 'Debit' : 'Credit ' }
                      {(row.Description.substring(0, 3) == 'Rev') ? '(Reversal)' : ''}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.Description}
                  </TableCell>
                  <TableCell style={{ width: 160, color: 'rgb(0, 171, 85)' }}>
                      Successful
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {humanDate(row.TranDate)}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.Balance}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                    {(row.DebitCredit == 'D')? <Button  onClick={() => getReceipt(row.RequestID)} type="button">Receipt</Button> : ''}
                      
                  </TableCell>
                </TableRow>
            ))}

            {emptyRows == 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8}><Typography style={{textAlign:'center', flexGrow:1}}>{loading}</Typography></TableCell>
                </TableRow>
            )}
            </TableBody>
            <TableFooter>
            <TableRow>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                    'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            
            <Box sx={style} style={{borderRadius: 12}} id="capture">
                <Typography id="modal-modal-description" fullWidth sx={{ mt: 2 }} align="left" style={{height:70,backgroundColor:'#3490dc',color:'white', paddingLeft:10, paddingTop:10, fontSize:15}}>
                    Transaction Receipt
                    <Box sx={{ px: 2, py: 1 }} style={{width: 100, float: 'right' }}>
                      {/* <Logo style={{textAlign:'right', flexGrow:1}} /> */}
                      <img alt="Pinspay Logo" src={pinspayLogo} width="150px" style={{textAlign:'right', flexGrow:1, position: "relative"}}/>
                    </Box>
                </Typography>
                <Box style={{paddingLeft:20, paddingRight:20}}>
                  <Typography fullWidth sx={{ mt: 2 }} align="center" style={{height:30,backgroundColor:'#38c172',color:'white', fontSize:13}}>
                      Transaction Details on {transfer_date}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table fullWidth aria-label="custom pagination table">
                    
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                Amount:
                            </TableCell>
                            <TableCell align="right" style={{padding:5}}>
                                {amount}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                Bank:
                            </TableCell>
                            <TableCell align="right" style={{padding:5}}>
                                {bank}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Beneficiary:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {beneficiary}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Credit Account:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {credit_account}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Debit Account:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {debit_account}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Transaction Type:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {transfer_type}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Description:
                              </TableCell>
                              <TableCell align="right" style={{padding:2}}>
                                  {description}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:2}}>
                                  Transaction Date:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {transfer_date}
                              </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{padding:5}}>
                                  Status:
                              </TableCell>
                              <TableCell align="right" style={{padding:5}}>
                                  {message}
                              </TableCell>
                          </TableRow>
                        </TableBody>
                        
                    </Table>
                    </TableContainer>
                </Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="left" style={{fontSize:11}}>
                    <b>Disclaimer:</b> Your transaction has been successfully processed. Note, however, that completion of any transfer may be affected by other factors including but not limited to transmission errors, incomplete information, fluctuations on the network/internet, interruptions, glitch, delayed information or other matters beyond the company's control which may impact on the transaction and for which the company will not be liable. All transactions are subject to Pinspay confirmation and fraud proof verification.
                </Typography>
                <Typography id="modal-modal-description" fullWidth sx={{ mt: 2 }} align="center" style={{height:70,backgroundColor:'#3490dc',color:'white', fontSize:13}}>
                    For any other assistance, please contact us on <br/>info@pinspay.com <br/> 012275020
                </Typography>
                
                
                <Typography className="MuiTypography-button"  data-html2canvas-ignore={true}>
                  <Button onClick={_exportPdf} type="button">Export PDF</Button>
                  <Button onClick={handleClose} type="button" style={{float:'right'}}>Close</Button>
                  
                </Typography>
            </Box>
        </Modal>
    </>
  );
}
