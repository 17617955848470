// import { useHistory } from "react-router-dom";
// ----------------------------------------------------------------------
const accountDet = JSON.parse(sessionStorage.getItem('pinsPayUser'));
// const mobilePhone = sessionStorage.getItem('phoneNumber');

console.log(global.userDetails);

let record = sessionStorage.getItem('pinspay_record');
let mobilePhone = ''
if(record != null){
  record = JSON.parse(record);
  mobilePhone = record.mobilePhone
}
// let history = useHistory();
console.log(mobilePhone);
if (mobilePhone === undefined || mobilePhone === '') {
  // eslint-disable-next-line no-restricted-globals
  // location.href = '/';
}
let accountName = '';
if (accountDet) {
  accountName = `${accountDet.firstName}  ${accountDet.lastName}`;
}
const account = {
  displayName: accountName,
  email: mobilePhone,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

export default account;
