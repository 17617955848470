import * as Yup from 'yup';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  ListSubheader,
  TextField,
  InputAdornment,
  Stack, IconButton, Input
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AlignHorizontalRight } from '@mui/icons-material';
import SearchIcon from "@mui/icons-material/Search";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  console.log(containsText);

export default function Transfer() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [serverMsg, setServerMsg] = React.useState('none');
  const [transactionStatus, setTransactionStatus] = React.useState('Pending');
  const [currentCount, setCount] = useState(100);
  const [transferReference, setTransferReference] = React.useState('');
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const [transferAmount, setTansferAmount] = React.useState('0');
  const [amountInputError, setAmountInputError] = useState('');
  const [refreshBtn, setRefreshBtn] = useState('Refresh');
  const [recipientName, setRecipientName] = useState('');
  const [recipientVerified, setRecipientVerified] = useState(0);
  const [bankCode, setBankCode] = useState('');
  const [recipientDetails, setRecipientDetails] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [allNigBanks, setAllNigBanks] = useState([]);
  const [checkButton, setCheckButton] = useState(false);
  const [transReference, setTransReference] = useState('');
  const [stopCountDown, setStopCountDown] = useState(0);
  const [isLoadingn, setIsLoadingn] = useState(false);

  console.log(selectedOption)
  console.log(bankCode)

  const [searchText, setSearchText] = useState("");
  // console.log(searchText)


  let scopen = sessionStorage.getItem('pinspayFetchUsers');
  let defBal = 0;
  if(scopen != null){
    scopen = JSON.parse(scopen);
    defBal = scopen.AvailableBalance
  }

  const [accountBalance, setAccountBalance] = useState(defBal);
  // console.log(defBal);

  let ric = 100;


  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  const mobilePhone = userDetails.mobilePhone

  const RegisterSchema = Yup.object().shape({
    pin: Yup.string().required('Transaction Pin required'),
    recipient: Yup.string().min(10, 'Too Short!').max(10, 'Too Long!').required('Recipient is required'),
    description: Yup.string().required('Description is required')
  });

  const defaultMobile = mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  let myInterval = '';
  let referenceP = ''

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const formatCurrency = (event) => {
    // console.log(event.target.value)
    let nonNeg = event.target.value
    nonNeg = nonNeg.replace(/,/g, "");
    nonNeg = parseFloat(nonNeg).toFixed(2).toString();
    nonNeg = nonNeg.replace(/[^\d.]/g, '');
    setTansferAmount(nonNeg)
    // nonNeg = Math.abs(nonNeg);
    let trn = parseFloat(nonNeg).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString()
    nonNeg = nonNeg.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');

    if(nonNeg.indexOf('.') === -1){
        if(nonNeg !== '')
            event.target.value = nonNeg + '.00';
    } else{
        var decimals = nonNeg.split('.')[1];

        event.target.value = decimals.length < 2 ? nonNeg + '0' : nonNeg;
        return;
    }
    // setTansferAmount(trn)
    // if (trn !== "NaN") {
    //     event.target.value = trn
    // }
  };

  const checkInput = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    nonNeg = nonNeg.replace(/[^\d.]/g, '');
    nonNeg = parseFloat(nonNeg)
    // console.log(nonNeg)
    if(nonNeg > accountBalance){
        console.log('Insufficient Balance')
        setAmountInputError('true')
    } else {
        setAmountInputError('false')
    }
    
    // event.target.value = nonNeg
  };

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  const checkRecipient = (event) => {
    let nonNeg = event.target.value
    // console.log(defaultMobile + " " + nonNeg);
    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    if (nonNeg.length === 10) {
      let accountRecip = fetchRecip(nonNeg);
    } else {
      setRecipientName('')
      setRecipientVerified(0)
    }
    
    // event.target.value = nonNeg
  };

  const handleBanks = (event) => {
    setBankCode(event.target.value);
    console.log(event.target.value);
    console.log(event.target.key);
  };


  async function transferFund(values) {
    if(transferAmount == 0 || transferAmount == 0.00) {
      Swal.fire({
        title: 'Error!',
        text: 'Amount cannot be less than 1',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    }
    setIsLoadingn(true);
    let recipientDetails = JSON.parse(sessionStorage.getItem('temp_recipDetails'))

    var cipherPin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
    // console.log(ciphertext);
    // console.log(recipientDetails)
    let bvno = recipientDetails.BankVerificationNumber
    let kyc = recipientDetails.KYCLevel
    let nameEnquiryReff = recipientDetails.NameEnquiryRef

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      amount: transferAmount,
      destAcct: values.recipient,
      destAcctName: recipientName,
      nameEnquiryRef: nameEnquiryReff,
      bvn: bvno,
      kycLevel: kyc,
      bankCode: bankCode,
      description: values.description,
      pin: cipherPin
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/interbanktransfer', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel': 'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === '900') {
          setOpen(false)
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
            navigate('/phone', { state: { defaultMobile } });
        } else if (response.status === '800') {
          setOpen(false)

          setIsLoadingn(false);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return pp;
        }else if (response.status === '300') {
          setIsLoadingn(false);
          setServerMsg(response.message)
          setTransactionStatus('Failed');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();
        } else if (response.status === '500') {
          setIsLoadingn(false);
          setServerMsg(response.message)
          setTransactionStatus('Failed');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();
        } else {
            // console.log(response);
            setServerMsg(response.message)
            setTransactionStatus('Pending');
            handleOpen();
            setTransferReference(response.reference);
            // console.log(response.reference);
            referenceP = response.reference;
            setTransReference(referenceP)
            setCheckButton(false);
            myInterval = setInterval(func,1000);
        }
      })
      .catch(function(err) {
        // console.log(err);
        var msg = "Technical error. Please again later";
            Swal.fire({
              title: 'Error!',
              text: msg,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
    })
    return pp;
  }

  async function checkFinal() {
    setCheckButton(true);
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      reference: referenceP,
        mobilePhone: defaultMobile,
        m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/finalstatus', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === '900') {
          setOpen(false);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
            navigate('/phone', { state: { defaultMobile } });
        } else if(response.status === '200') {
          setOpen(false)
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              let dfu = fetchBal(defaultMobile, 1)
              
            });
        }else {
            setTransactionStatus(response.message);
            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              // navigate('/dashboard', { state: { defaultMobile } })
              window.location.href="/dashboard"
              
            });
        }
      });
      setCheckButton(true);
    return pp;
  }

  async function checkStatus() {
    var ref = transReference;
    if(transReference == ''){ ref = referenceP}
    setTransactionStatus('Please wait...');
    setCheckButton(true);

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      reference: ref,
        mobilePhone: defaultMobile,
        m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/transferstatus', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
          if (response.status === '900') {
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            logOut()
              navigate('/phone', { state: { defaultMobile } });
          } else if (response.status === '200'){
            setTransactionStatus(response.message);
            setStopCountDown(1)
            setCheckButton(true);

            setOpen(false)
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              let dfu = fetchBal(defaultMobile, 1)
            });

            return pp;
          } else if (response.status === '300'){
            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              // navigate('/dashboard', { state: { defaultMobile } })
              window.location.href="/dashboard"
            });
          }else {
              setTransactionStatus(response.message);
          }
          
      });
      setCheckButton(false);
    return pp;
  }

  function func(){
      if (ric > 0) {
          if (ric === 80) {
              checkStatus();
          }

          if (ric === 40) {
            checkStatus();
          }
        ric = ric -1;
        setCount(ric);
        // console.log(ric)
      } else if(ric === 0) {
          checkFinal();
          clearInterval(myInterval);
        //   console.log("that's enough");
      }
    
  }

  function Clock() {
    const timer = () => setCount(currentCount - 1);

    useEffect(
        () => {
            if (currentCount <= 0) {
                return;
            }
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        },
        [currentCount]
    );

    return currentCount;
};
let allBanks = []


useEffect(() => {
  const getBanks = async () => {
    try{
      const res = await fetch(Config.base_url+"/getbanks", {
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            'channel':'WEB'
          },
      })
      
      const response = await res.json();
      if(response.status == '200'){
        console.log(response.data);
        allBanks = response.data
        setAllNigBanks(allBanks)
      } else {
        allBanks = ['q']
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
    catch(e){
      Swal.fire({
        title: 'Error!',
        text: "Technical error. Unable to fetch Banks",
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
  }
  
  
  let fgf = getBanks();
  // console.log(fgf)
  
  
},[])

const displayedOptions = useMemo(
  () => allNigBanks.filter((option) => containsText(option.BankName, searchText)),
  [searchText]
);

// console.log(allBanks)

// const loginToken = sessionStorage.getItem('pinspayLoginToken');
async function fetchRecip(acct_no) {
  if (bankCode == '') {
    return 'Select Bank'
  }
  setRecipientName('Loading...');

  var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      destinationAcct: acct_no,
      bankCode: bankCode
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

  const pp = await fetch(Config.base_url+'/interbankname', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'pwatoken': loginToken,
      'channel':'WEB'
    },
    body: JSON.stringify({
      rqt: cipherRxq,
      channel: 'WEB'
    })
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.status === '200') {
        setRecipientName(response.data.AccountName);
        console.log(recipientName);
        // setRecipientDetails();
        sessionStorage.setItem('temp_recipDetails', JSON.stringify(response.data));
        setRecipientVerified(1)
      } else if (response.status === '300'){
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        setRecipientName('')
        setRecipientVerified(0)
      } else if (response.status === '900'){
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            logOut()
        setRecipientName('')
        setRecipientVerified(0)
      } else if (response.status === '600'){
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        setRecipientName('')
        setRecipientVerified(0)
      } else {
        
      }
    })
    .catch(function(err) {
      console.log(err);
      // setRecipientName('');
  })
  return pp;
}

async function fetchBal(mobilePhones, z=0) {
    setRefreshBtn('Loading...');
    // setAccountBalance('-');
    const pp = await fetch(Config.base_url+'/checkbal', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        mobilePhone: mobilePhones,
        pwa_token: loginToken
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === '200') {
          setAccountBalance(response.data);

          var rt = JSON.parse(sessionStorage.getItem('pinspayFetchUsers'));
          rt.AvailableBalance = response.data;
          console.log(rt);
          sessionStorage.setItem('pinspayFetchUsers', JSON.stringify(rt));

          if(z == 1) {
            // navigate('/dashboard', { state: { defaultMobile } })
            window.location.href="/dashboard"
          }
          setRefreshBtn('Refresh');
          return true;
        } else if(response.status === '800'){
          setServerMsg(response.message)
          setTransactionStatus('Incorrect Pin');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();

          setIsLoadingn(false);
          setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            return true;
        } else if (response.status === '900'){
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            logOut()
          navigate('/phone', { state: { mobilePhones } });
        } else {
          if(z == 1) {
            // navigate('/dashboard', { state: { defaultMobile } })
            window.location.href="/dashboard"
            return true;
          }
            setRefreshBtn('Refresh');
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }
      })
      .catch(function(err) {
        // console.log(err);
        if(z == 1) {
          // navigate('/dashboard', { state: { defaultMobile } })
          window.location.href="/dashboard"
          return true;
        }
        setRefreshBtn('Refresh');
        Swal.fire({
          title: 'Error!',
          text: 'Technical error. Try again later',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        // setAccountBalance(scopen);
    })
    return pp;
  }

//   const check = fetchBal(defaultMobile);

  const { state } = useLocation();

  // const realMobile = defaultMobile;

  const formik = useFormik({
    initialValues: {
      pin: '',
      recipient: '',
      description: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
    //   console.log(values);
      transferFund(values);
      // navigate('/dashboard', { replace: true });
    }
  });

  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };



  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [dValue, setdValue] = useState(null);
  const acctBal =(accountBalance == '-' ? accountBalance : parseFloat(accountBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
  return (
    <>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={6}>
            
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Banks</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Banks"
             
                // onChange={handleBanks}
                onChange={(e) => {
                  // setSearchText(e.target.value)
                  var pl = e.target.value
                  var selName = ''
                  allNigBanks.map(function(pps) {
                    if(pps.BankCode === pl){
                      selName = pps.BankName
                      return;
                    }
                  });
                  setSelectedOption(selName)
                  setBankCode(e.target.value);
                }}


                MenuProps={{ autoFocus: false }}
                value={selectedOption}
                // onChange={(e) => setSelectedOption(e.target.value)}
                onClose={() => setSearchText("")}
                // This prevents rendering empty string in Select's value
                // if search text would exclude currently selected option.
                renderValue={() => selectedOption}
              >
                <ListSubheader>
                  <TextField
                    size="small"
                    // Autofocus on textfield
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value)
                      console.log(e.key)
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {
                (displayedOptions !="") ?
                displayedOptions.map((bank, i) =>
                  <MenuItem key={i} value={bank.BankCode} >{bank.BankName}</MenuItem>
                )
               :
                  allNigBanks.map((bank, i) =>
                  <MenuItem key={i} value={bank.BankCode} >{bank.BankName}</MenuItem>
                )
                }

                

                
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                  fullWidth
                  autoComplete="recipient"
                  type="text"
                  onInput={checkRecipient}
                  inputProps={{ maxLength: 10 }}
                  label="Recipient Account Number"
                  {...getFieldProps('recipient')}
              />
              <Typography style={{textAlign:'right', flexGrow:1}}><span style={{color:'#00AB55'}}>{recipientName}</span></Typography>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Amount (₦)</InputLabel>
                <Input
                    // error
                    fullWidth
                    // type="number"
                    // className="Mui-error"
                    inputProps={{ pattern: "^\d+(\.|\,)\d{2}$" }}
                    onInput={checkInput}
                    variant="outlined"
                    onBlur={formatCurrency}
                />
                <Typography style={{textAlign:'right', flexGrow:1}}><span style={{color:'#00AB55'}}>Acct Bal: ₦ {acctBal}</span> | <small style={{cursor:'pointer',color:'blue'}} onClick={() => fetchBal(defaultMobile)}>{refreshBtn}</small></Typography>
            </FormControl>
            
            <TextField
                id="standard-multiline-static"
                label="Description"
                multiline
                rows={4}
                variant="standard"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
            />
            
            <TextField
                fullWidth
                autoComplete="Pin"
                type="password"
                label="Transaction Pin"
                onInput={checkPin}
                inputProps={{ maxLength: 4 }}
                {...getFieldProps('pin')}
                error={Boolean(touched.pin && errors.pin)}
                helperText={touched.pin && errors.pin}
            />

            <LoadingButton
                disabled={(transferAmount > 0) ? false : true}
                size="large"
                type="submit"
                variant="contained"
                loading={isLoadingn}
            >
                Transfer
            </LoadingButton>
            </Stack>
        </Form>
        </FormikProvider>
        {/* <Clock /> */}
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{borderRadius: 12}}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
                    {serverMsg}
                </Typography>
                <Typography id="modal-modal-title" variant="h2" component="h1" align="center">
                  {(stopCountDown==1)? 0 : currentCount} sec
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    Transaction Status: <span style={{fontWeight:900}}>{transactionStatus}</span>
                </Typography>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{textAlign:'right', marginTop:20}}
                  disabled={(checkButton || stopCountDown==1) ? true : false}
                  onClick={checkStatus}
                  >
                  Check
                </LoadingButton>
                
                <RouterLink className="MuiTypography-button" to="/dashboard">Dashboard</RouterLink>
            </Box>
        </Modal>
    </>
  );
}
