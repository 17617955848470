import { Icon } from '@iconify/react';
import React, { Component }  from 'react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import ViewListIcon from '@mui/icons-material/ViewList';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import listFill from '@iconify/icons-eva/list-fill';
import send from '@iconify/icons-ic/send';
import balance from '@iconify/icons-ic/account-balance';
import NumbersIcon from '@mui/icons-material/Numbers';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'transactions',
    path: '/dashboard/transactions',
    icon: getIcon(listFill)
  },
  {
    title: 'transfer to pinspay',
    path: '/dashboard/transfer',
    icon: getIcon(send)
  },
  {
    title: 'transfer to bank',
    path: '/dashboard/bank_transfer',
    icon: getIcon(balance)
  },
  {
    title: 'Unclaimed Funds',
    path: '/dashboard/unclaimed',
    icon: getIcon(balance)
  },
  {
    title: '00# Customization',
    path: '/dashboard/customeauth',
    icon: getIcon(settings2Fill)
  },
  {
    title: 'account',
    path: '/dashboard/account',
    icon: getIcon(settings2Fill)
  }
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];



export default sidebarConfig;
