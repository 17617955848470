import { Icon } from '@iconify/react';
import React, { Component }  from 'react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css'

// ----------------------------------------------------------------------

var MENU_OPTIONS = [
  {
    label: 'Dashboard',
    icon: homeFill,
    linkTo: '/dashboard/app'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '/dashboard/account'
  }
];

const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
// console.log(userDetails.kyc_level)

if(userDetails != null){
  if (userDetails.kyc_level==1) {
    MENU_OPTIONS = [...MENU_OPTIONS, {label: 'Upgrade To Tier 2',linkTo: '/dashboard/tiertwo',icon: settings2Fill},{label: 'Upgrade To Tier 3',linkTo: '/dashboard/tierthree',icon: settings2Fill}]
  }else if(userDetails.kyc_level==2){
    MENU_OPTIONS = [...MENU_OPTIONS, {label: 'Upgrade To Tier 3',linkTo: '/dashboard/tierthree',icon: settings2Fill}]
  } else {
    MENU_OPTIONS = [...MENU_OPTIONS]
  }
}

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

    // Logout Function
    const logOut = () => {
      console.log('Logged out');
      global.isLogin = false;
      global.loginToken = '';
      sessionStorage.removeItem("pinspayFetchUsers");
      sessionStorage.removeItem("pinspay_record");
      sessionStorage.removeItem("pinspayLoginToken");
      sessionStorage.removeItem("pinspaySuccessfulTransaction");
      // eslint-disable-next-line no-restricted-globals
      location.href = '/';
    };

  var pprecord = sessionStorage.getItem('pinspay_record')
  console.log(pprecord)

  
  if(pprecord == null){
    logOut()
  }
  let record = JSON.parse(pprecord);
  let mobileP = record.mobilePhone
  // console.log(record)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {mobileP}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logOut}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
