import * as Yup from 'yup';
import React, { Component }  from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------

export default function CreatePasswordForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');

  const LoginSchema = Yup.object().shape({
    pin: Yup.string().required('Pin is required').max(4, 'Too Long!').min(4, 'Too Short!'),
    password: Yup.string().required('Password is required').min(6, 'Too Short!'),
    c_password: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match')
  });

  const { state } = useLocation();
  // console.log(state);
  if (state == null) {
    navigate('/', { replace: true });
  }
  const defaultMobile = state.mobilePhones;
  const purpose = state.purpose;
  const realMobile = defaultMobile;
  // console.log(defaultMobile);

  function enc(str) {
    var encoded = "";
    for (var i=0; i<str.length;i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 123;    // bitwise XOR with any number, e.g. 123
        encoded = encoded+String.fromCharCode(b);
    }
    return encoded;
  }

  async function enterPin(pins) {
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      pin: pins,
      mobilePhone: realMobile
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/enterpin', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch(function(err) {
        // console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    return pp;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
      c_password: '',
      pin: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      // console.log(values);
      values.password = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
      values.pin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
      const check = await enterPin(values.pin);
      if (check.status === '200') {
        const allValues = { ...values, mobilePhone: defaultMobile, purpose };
        sessionStorage.setItem('pinspayPwd', JSON.stringify(allValues));
        navigate('/verifyphone', { state: { realMobile, url: 'createpassword' } });
      } else {
        // alert(check.message)
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const phoneNumber = global.mobilePhone;
  const isPhone = true;
  // alert(phoneNumber);
  if (phoneNumber === '' || phoneNumber === null) {
    // isPhone = false;
    // navigate('/phone', { replace: true });
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // console.log(defaultMobile);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <TextField
            fullWidth
            type={showPassword ? 'number' : 'password'}
            label="Your Four Digits Pin"
            {...getFieldProps('pin')}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.pin && errors.pin)}
            helperText={touched.pin && errors.pin}
          />

          <TextField
            fullWidth
            // autoComplete="Phone"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm New Password"
            {...getFieldProps('c_password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.c_password && errors.c_password)}
            helperText={touched.c_password && errors.c_password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Continue
        </LoadingButton>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go home
          </Link> */}
          <RouterLink component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go Back
          </RouterLink> 
        </Stack>
      </Form>
    </FormikProvider>
  );
}
