import { Navigate, useRoutes } from 'react-router-dom';
import React, { Component }  from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Phone from './pages/Phone';
import ForgetPassword from './pages/ForgetPassword';
import Register from './pages/Register';
import RegisterCont from './pages/RegisterCont';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import ProductsBank from './pages/ProductsBank';
import Account from './pages/Account';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import CreatePassword from './pages/createpassword';
import VerifyPhone from './pages/verifyphone';
import { UnclaimedFunds } from './components/_dashboard/unclaimed_fund';
import Customeauth from './pages/Customeauth';
import Tiertwo  from './pages/Tiertwo';
import Tierthree from './pages/Tierthree';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'transactions', element: <User /> },
        { path: 'transfer', element: <Products /> },
        { path: 'bank_transfer', element: <ProductsBank /> },
        { path: 'account', element: <Account /> },
        { path: 'unclaimed', element: <UnclaimedFunds /> },
        { path: 'customeauth', element: <Customeauth /> },
        { path: 'tiertwo', element: <Tiertwo /> },
        { path: 'tierthree', element: <Tierthree /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'phone', element: <Phone /> },
        { path: 'forgetpassword', element: <ForgetPassword /> },
        { path: 'createpassword', element: <CreatePassword /> },
        { path: 'verifyphone', element: <VerifyPhone /> },
        { path: 'register', element: <Register /> },
        { path: 'registercont', element: <RegisterCont /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/phone" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
