import * as Yup from 'yup';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AlignHorizontalRight } from '@mui/icons-material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';

// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function Transfer() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [serverMsg, setServerMsg] = React.useState('none');
  const [transactionStatus, setTransactionStatus] = React.useState('Pending');
  const [currentCount, setCount] = useState(100);
  const [transferReference, setTransferReference] = React.useState('');
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }
  const [transferAmount, setTansferAmount] = React.useState('0');
  const [amountInputError, setAmountInputError] = useState('');
  const [refreshBtn, setRefreshBtn] = useState('Refresh');
  const [recipientName, setRecipientName] = useState('');
  const [recipientVerified, setRecipientVerified] = useState(0);
  const [unknownRecipient, setUnknownRecipient] = useState(true);
  const [language, setLang] = useState('');
  const [recipientPins, setRecipientPins] = useState('');
  const [isLoadingn, setIsLoadingn] = useState(false);

  const [checkButton, setCheckButton] = useState(false);
  const [transReference, setTransReference] = useState('');
  const [stopCountDown, setStopCountDown] = useState(0);

  let scopen = sessionStorage.getItem('pinspayFetchUsers');
  let defBal = 0;
  if(scopen != null){
    scopen = JSON.parse(scopen);
    defBal = scopen.AvailableBalance
  }

  const handleLang = (event) => {
    setLang(event.target.value);
    console.log(event.target.value);
  };


  const [accountBalance, setAccountBalance] = useState(defBal);

  let ric = 100;


  const userDetails = JSON.parse(sessionStorage.getItem('pinspayFetchUsers'));

  const RegisterSchema = Yup.object().shape({
    pin: Yup.string().required('Transaction Pin required'),
    recipient: Yup.string().min(11, 'Too Short!').max(11, 'Too Long!').required('Recipient is required'),
    description: Yup.string().required('Description is required')
  });


  const record = JSON.parse(sessionStorage.getItem('pinspay_record'))
  
  const defaultMobile = record.mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  let myInterval = '';
  let referenceP = ''

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const formatCurrency = (event) => {
    // console.log(event.target.value)
    let nonNeg = event.target.value
    nonNeg = nonNeg.replace(/,/g, "");
    nonNeg = parseFloat(nonNeg).toFixed(2).toString();
    nonNeg = nonNeg.replace(/[^\d.]/g, '');
    setTansferAmount(nonNeg)
    // nonNeg = Math.abs(nonNeg);
    let trn = parseFloat(nonNeg).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString()
    nonNeg = nonNeg.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');

    if(nonNeg.indexOf('.') === -1){
        if(nonNeg !== '')
            event.target.value = nonNeg + '.00';
    } else{
        var decimals = nonNeg.split('.')[1];

        event.target.value = decimals.length < 2 ? nonNeg + '0' : nonNeg;
        return;
    }
    // setTansferAmount(trn)
    // if (trn !== "NaN") {
    //     event.target.value = trn
    // }
  };

  const checkInput = (event) => {
    // console.log('yes');
    let nonNeg = event.target.value
    
    nonNeg = nonNeg.replace(/[A-Za-z]/g, "")
    event.target.value = nonNeg;
    nonNeg = nonNeg.replace(/[^\d.]/g, '');
    nonNeg = parseFloat(nonNeg)
    // console.log(nonNeg)
    if(nonNeg > accountBalance){
        console.log('Insufficient Balance')
        setAmountInputError('true')
    } else {
        setAmountInputError('false')
    }
    
    // event.target.value = nonNeg
  };

  const checkRecipient = (event) => {
    let nonNeg = event.target.value
    // console.log(defaultMobile + " " + nonNeg);
    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    if (nonNeg.length === 11) {
      var defaultMobile1 = defaultMobile.substr(defaultMobile.length - 10); 
      var nonNeg1 = nonNeg.substr(nonNeg.length - 10); 
      if (defaultMobile1 === nonNeg1) {
          var msg = 'You cannot transfer to yourself';
          Swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          event.target.value = ''
          return;
      }
      let accountRecip = fetchRecip(nonNeg);
    } else {
      setRecipientName('')
      setRecipientVerified(0)
    }
    
    // event.target.value = nonNeg
  };

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  const checkPins = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    setRecipientPins(event.target.value);
  }

  async function transferFund(values) {
    if(transferAmount == 0 || transferAmount == 0.00) {
      Swal.fire({
        title: 'Error!',
        text: 'Amount cannot be less than 1',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    }
    setIsLoadingn(true);
    var cipherPin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
    var recipPin = CryptoJS.AES.encrypt(recipientPins, 'secret key kpngz@!1234').toString();

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      amount: transferAmount,
        receiver: values.recipient,
        description: values.description,
        pin: cipherPin,
        recipPin: recipPin,
        lang: language
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/transfer', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === '900') {
            // alert(response.message);
            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            logOut()
            navigate('/phone', { state: { defaultMobile } });
        } else if (response.status === '300') {
          setIsLoadingn(false);
          setServerMsg(response.message)
          setTransactionStatus('Failed');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();

          setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            .then(() => window.location.href="/dashboard");
        } else if (response.status === '800') {
          setServerMsg(response.message)
          setTransactionStatus('Incorrect Pin');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();

          setIsLoadingn(false);
          setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            return true;
        } else if (response.status === '500') {
          setServerMsg(response.message)
          setTransactionStatus('Failed');
          clearInterval(myInterval); //stop countdown
          setCheckButton(true);
          handleOpen();

          setIsLoadingn(false);
          setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        } else {
            // console.log(response);
            setServerMsg(response.message)
            setTransactionStatus('Pending');
            handleOpen();
            setTransferReference(response.reference);
            // console.log(response.reference);
            referenceP = response.reference;
            setTransReference(referenceP)
            setCheckButton(false);
            myInterval = setInterval(func,1000);
        }
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    return pp;
  }

  async function checkFinal() {

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      reference: referenceP
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/finalstatus', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === '900') {
            // alert(response.message);
            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            logOut()
            // navigate('/phone', { state: { defaultMobile } });
        } else if (response.status === '200'){
          setTransactionStatus(response.message);
          setCheckButton(true);

          setOpen(false)
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              let dfu = fetchBal(defaultMobile, 1)
              
            });
            setCount(0)
        }else {
            setTransactionStatus(response.message);
            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              // navigate('/dashboard', { state: { defaultMobile } })
              window.location.href="/dashboard"
              
            });
        }
      });
      setCheckButton(true);
    return pp;
  }

  async function checkStatus(myInterval) {
    var ref = transReference;
    if(transReference == ''){ ref = referenceP}
    setTransactionStatus('Please wait...');
    setCheckButton(true);
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      reference: ref
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/transferstatus', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
          if (response.status === '900') {
              // alert(response.message);
              Swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
              logOut()
              navigate('/phone', { state: { defaultMobile } });
          } else if(response.status === '200') {
            setTransactionStatus("Transaction Successful");
            setStopCountDown(1)
            ric = 0;
            myInterval = setInterval(func,1000);
            clearInterval(myInterval); //stop countdown

            setOpen(false)
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              let dfu = fetchBal(defaultMobile, 1)
              
            });
            setCount(0)
            return true;
          } else if(response.status === '300') {
            setTransactionStatus("Transaction Failed");
            setStopCountDown(1)
            ric = 0;
            myInterval = setInterval(func,1000);
            clearInterval(myInterval); //stop countdown

            setOpen(false)
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            .then(() => {
              // navigate('/dashboard', { state: { defaultMobile } })
              window.location.href="/dashboard"
              
            });
            setCount(0)
            return true;
          } else {
              setTransactionStatus(response.message);
          }
          
      });
      setCheckButton(false);
    return pp;
  }

  function func(a=myInterval){
      if (ric > 0) {
          if (ric === 80) {
              checkStatus();
          }

          if (ric === 40) {
            checkStatus();
          }
        ric = ric -1;
        setCount(ric);

        if (stopCountDown == 1) {
          clearInterval(a);
          alert('stop timer');
          console.log('stop timer');
        }
        console.log(stopCountDown)
      } else if(ric === 0) {
          checkFinal();
          clearInterval(a);
        //   console.log("that's enough");
      }
    
  }

  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };

  function Clock() {
    const timer = () => setCount(currentCount - 1);

    useEffect(
        () => {
            if (currentCount <= 0) {
                return;
            }
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
        },
        [currentCount]
    );

    return currentCount;
};

async function fetchRecip(mobilePhones) {
  setRecipientName('Loading...');

  

  const pp = await fetch(Config.base_url+'/validateAccount', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'channel':'WEB'
    },
    body: JSON.stringify({
      mobilePhone: mobilePhones,
      channel:'WEB'
    })
  })
    .then((response) => response.json())
    .then((response) => {
      // console.log(response);
      if (response.status === '200') {
        let recipName = response.data.firstName + " " + response.data.lastName;
        console.log(recipName);
        setRecipientName(recipName);
        setRecipientVerified(1)
        return response;
      } else if (response.status === '300'){
        setRecipientName('Unknown User');
        setUnknownRecipient(false);
        setRecipientVerified(1)
      } else if (response.status === '900'){
        // alert(response.message);
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: '' });
      } else if (response.status === '600'){
        // alert(response.message);
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setRecipientName('');
        setRecipientVerified(0)
        // navigate('/phone', { state: '' });
      } else {
        setRecipientName('');
        setRecipientVerified(0)
      }
    })
    .catch(function(err) {
      console.log(err);
      setRecipientName('');
  })
  return pp;
}

async function fetchBal(mobilePhones, z=0) {
    setRefreshBtn('Loading...');
    // setAccountBalance('-');

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      pwa_token: loginToken
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/checkbal', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.status === '200') {
          global.availableBalance = response.data;
          //   console.log(global.availableBalance);
          setAccountBalance(response.data);
          var rt = JSON.parse(sessionStorage.getItem('pinspayFetchUsers'));
          rt.AvailableBalance = response.data;
          console.log(rt);
          sessionStorage.setItem('pinspayFetchUsers', JSON.stringify(rt));
          // console.log(accountBalance)

          if(z == 1) {
            // navigate('/dashboard', { state: { defaultMobile } })
            window.location.href="/dashboard"
          }

          setRefreshBtn('Refresh');
          return response;
        } else if (response.status === '900'){
          // alert(response.message);
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
          navigate('/phone', { state: { accountBalance } });
        } else {
          if(z == 1) {
            // navigate('/dashboard', { state: { defaultMobile } })
            window.location.href="/dashboard"
            return true;
          }
            setRefreshBtn('Refresh');
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
        }
      })
      .catch(function(err) {
        console.log(err);
        setRefreshBtn('Refresh');
        Swal.fire({
          title: 'Error!',
          text: 'Technical error. Try again later',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        // setAccountBalance(scopen);
    })
    return pp;
}
  

//   const check = fetchBal(defaultMobile);

  const { state } = useLocation();

  const realMobile = defaultMobile;

  const formik = useFormik({
    initialValues: {
      pin: '',
      recipient: '',
      description: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
    //   console.log(values);
      transferFund(values);
      // navigate('/dashboard', { replace: true });
    }
  });
  

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [dValue, setdValue] = useState(null);

  const acctBal =(accountBalance == '-' ? accountBalance : parseFloat(accountBalance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));

  return (
    <>
        <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={7}>
            {/* <TextField
                fullWidth
                autoComplete="amount"
                type="number"
                label="Amount"
                onBlur={formatCurrency}
                {...getFieldProps('amount')}
                error={Boolean(touched.amount && errors.amount)}
                helperText={touched.amount && errors.amount}
            /> */}
            {/* <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth error={amountInputError}> */}
            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Amount (₦)</InputLabel>
                <Input
                    // error
                    fullWidth
                    // type="number"
                    // defaultValue={0.00}
                    // className="Mui-error"
                    inputProps={{ pattern: "^\d+(\.|\,)\d{2}$" }}
                    onInput={checkInput}
                    variant="outlined"
                    onBlur={formatCurrency}
                />
                <Typography style={{textAlign:'right', flexGrow:1}}><span style={{color:'#00AB55'}}>Acct Bal: ₦ {acctBal}</span> | <small style={{cursor:'pointer',color:'blue'}} onClick={() => fetchBal(defaultMobile)}>{refreshBtn}</small></Typography>
            </FormControl>
            <FormControl>
              <TextField
                  fullWidth
                  type="tel"
                  onInput={checkRecipient}
                  inputProps={{ maxLength: 11 }}
                  label="Recipient Mobile Number"
                  {...getFieldProps('recipient')}
              />
              <Typography style={{textAlign:'right', flexGrow:1}}><span style={{color:'#00AB55'}}>{recipientName}</span></Typography>
            </FormControl>

            

            <Stack sx={unknownRecipient && { display: 'none' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
                <Select
                  name="language"
                  fullWidth
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={language}
                  label="Language"
                  onChange={handleLang}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Yoruba">Yoruba</MenuItem>
                  <MenuItem value="Igbo">Igbo</MenuItem>
                  <MenuItem value="Hausa">Hausa</MenuItem>
                  <MenuItem value="Pigin">Pigin</MenuItem>
                </Select>
                {/* <FormHelperText>i</FormHelperText> */}
              </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
              <TextField
                fullWidth
                autoComplete="recipientPin"
                type="password"
                onInput={checkPins}
                label="Withdrawal Pin"
                inputProps={{ maxLength: 4 }}
                {...getFieldProps('recipientPin')}
                error={Boolean(touched.recipientPin && errors.recipientPin)}
                helperText={touched.recipientPin && errors.recipientPin}
              />
            </FormControl>
          </Stack>

            <TextField
                id="standard-multiline-static"
                label="Description"
                multiline
                rows={4}
                variant="standard"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
            />
            
            <TextField
                fullWidth
                autoComplete="Pin"
                type="password"
                onInput={checkPin}
                label="Transaction Pin"
                inputProps={{ maxLength: 4 }}
                {...getFieldProps('pin')}
                error={Boolean(touched.pin && errors.pin)}
                helperText={touched.pin && errors.pin}
            />

            <LoadingButton
                disabled={(recipientVerified && transferAmount > 0) ? false : true}
                size="large"
                type="submit"
                variant="contained"
                loading={isLoadingn}
            >
                Transfer
            </LoadingButton>
          </Stack>
        </Form>
        </FormikProvider>
        {/* <Clock /> */}
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{borderRadius: 12}}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
                    {serverMsg}
                </Typography>
                <Typography id="modal-modal-title" variant="h2" component="h1" align="center">
                    {(stopCountDown==1)? 0 : currentCount} sec
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    Transaction Status: <span style={{fontWeight:900}}>{transactionStatus}</span>
                </Typography>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{textAlign:'right', marginTop:20}}
                  disabled={(checkButton || stopCountDown==1) ? true : false}
                  onClick={checkStatus}
                  // disabled={(stopCountDown==1) ? true : false}
                >
                  Check
                </LoadingButton>
                
                <RouterLink className="MuiTypography-button" to="/dashboard">Dashboard</RouterLink>
            </Box>
        </Modal>
    </>
  );
}
