import * as Yup from 'yup';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Config from '../../Helper/Config';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [languages, setLang] = useState('');
  const [genders, setGender] = useState('');
  // const [dobs, setDob] = useState(null);

  const handleChange = (event) => {
    setLang(event.target.value);
    // console.log(event.target.value);
  };

  const handleGender = (event) => {
    setGender(event.target.value);
    // console.log(event.target.value);
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    language: Yup.string().required('Language is required'),
    gender: Yup.string().required('Gender is required'),
    bvn: Yup.string().min(11, 'Too Short!').max(11, 'Too Long!'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(15, 'Too Long!')
      .required('Password is required'),
    c_password: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match')
  });

  const { state } = useLocation();
  // console.log(useLocation());
  if (useLocation().state === null) {
    
    window.location.href = './'
  }
  const defaultMobile = state.mobilePhones;
  const realMobile = defaultMobile;

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      c_password: '',
      language: '',
      gender: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // const realDate = Intl.DateTimeFormat('en-UK', {
      //   year: 'numeric',
      //   month: '2-digit',
      //   day: '2-digit'
      // }).format(dobs);
      // console.log(values);
      values.password = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
      values.c_password = CryptoJS.AES.encrypt(values.password, 'secret key kpngz@!1234').toString();
      // values.pin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
      // values.c_pin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
      const allValues = { ...values, mobilePhone: realMobile };
      const passwordArray = {
        password: values.password,
        c_password: values.c_password,
        mobilePhone: defaultMobile
      };
      // console.log(allValues);
      const lang = values.language;
      sessionStorage.setItem('pinspayCreatUser', JSON.stringify(allValues));
      sessionStorage.setItem('pinspayPwd', JSON.stringify(passwordArray));
      navigate('/verifyphone', { state: { realMobile, lang, url: 'register' } });
      // navigate('/dashboard', { replace: true });
    }
  });

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  const checkName = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[^A-Za-z]+/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  

  const [dValue, setdValue] = useState(null);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              onInput={checkName}
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              onInput={checkName}
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <TextField fullWidth label="Phone Number" disabled value={defaultMobile} />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
              <Select
                name="language"
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                // value={language}
                label="Language"
                onChange={handleChange}
                {...getFieldProps('language')}
                error={Boolean(touched.language && errors.language)}
                helperText={touched.language && errors.language}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Yoruba">Yoruba</MenuItem>
                <MenuItem value="Igbo">Igbo</MenuItem>
                <MenuItem value="Hausa">Hausa</MenuItem>
                <MenuItem value="Pidgin">Pidgin</MenuItem>
              </Select>
              {/* <FormHelperText>i</FormHelperText> */}
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
              <Select
                name="gender"
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select"
                // value={gender}
                label="Gender"
                onChange={handleGender}
                {...getFieldProps('gender')}
                error={Boolean(touched.gender && errors.gender)}
                helperText={touched.gender && errors.gender}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
              {/* <FormHelperText>i</FormHelperText> */}
            </FormControl>
          </Stack>

          

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('c_password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.c_password && errors.c_password)}
              helperText={touched.c_password && errors.c_password}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}

          {/* <Link component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go home
          </Link> */}
          <RouterLink component={RouterLink} variant="subtitle2" to="/phone" query={{ phone: 'defaultMobile' }}>
            Go Back
          </RouterLink> 
        </Stack>
      </Form>
    </FormikProvider>
  );
}
