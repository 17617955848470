import * as Yup from 'yup';
import React, { Component }  from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { encrypt, decrypt } from 'encryptor-node';
// ----------------------------------------------------------------------


export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('');

  const isToken = sessionStorage.getItem('pinspayLoginToken');

  if(isToken){
    window.location.href='./Dashboard/app';
  }
// const secret = 's3cr3t!';
// const payload = { message: 'This is an very important message' };
 
// Encrypting
// const encrypted = encrypt(secret, payload);
// console.log(encrypted); // e20f64009fe0daa88......
 
// Decrypting
// const result = decrypt(secret, encrypted);
// console.log(result); 

  

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required('Phone Number is required').max(11, 'Phone number too long').min(11, 'Phone number too short')
  });

  sessionStorage.clear();
    localStorage.clear();

  async function callOut(mobilePhones) {
    sessionStorage.clear();
    localStorage.clear();
    const pp = await fetch(Config.base_url+'/validateAccount', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        'channel':'WEB'
      },
      body: JSON.stringify({
        mobilePhone: mobilePhones,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch(function(err) {
        console.log(err);
        // alert("Technical error. Please again later");
        Swal.fire({
          title: 'Error!',
          text: err,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    return pp;
  }

  const formik = useFormik({
    initialValues: {
      phone: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      // alert(JSON.stringify(values, null, 2));
      const mobilePhones = values.phone;
      localStorage.setItem('pinspayNo', mobilePhones);

      const check = await callOut(mobilePhones);
      // console.log(check.status);
      if (check.status === '300') {
        sessionStorage.setItem('phoneNumber', mobilePhones);
        // navigate('/verifyphone', { state: { mobilePhones, url: '/register' } });
        localStorage.setItem('rxq', check.rsq)
        navigate('/register', { state: { mobilePhones } });
      } else if ((check.status === '200') || (check.status === '302')) {
        const datap = check.data;
        var pinq = check.data.rsq != undefined ? check.data.rsq : check.rsq
        
        if (datap.pwa_status === 0) {
          // console.log('creatp');
          localStorage.setItem('rxq', pinq)
          navigate('/createpassword', { state: { mobilePhones, 'purpose':'createpassword' } });
        } else if (datap.pwa_status === 1) {
          // console.log('login');
          
          localStorage.setItem('rxq', pinq)
          navigate('/login', { state: { mobilePhones } });
        }
      } else if (check.status === '400') {
        localStorage.setItem('rxq', check.rsq)
        navigate('/verifyphone', { state: { mobilePhones, url: '/createpassword' } });
      } else if (check.status === '600') {
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      } else {
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const checkMobile = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack>
            <TextField
              fullWidth
              autoComplete="Phone"
              onInput={checkMobile}
              type="text"
              label="Your Phone Number"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            {/* <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            /> */}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            /> */}

            {/* <Link component={RouterLink} variant="subtitle2" to="#">
              Forgot password?
            </Link> */}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Proceed
          </LoadingButton>
        </Form>
      </FormikProvider>
      
  </>
  );
}
