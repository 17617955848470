import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// material
import { Box, Grid, Container, Typography } from '@mui/material';
import axios from "axios";
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales
} from '../components/_dashboard/app';
import Modal from '@mui/material/Modal';
import { LoadingButton } from '@mui/lab';
import Table from '@mui/material/Table';
import { Stack, TextField, InputAdornment } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../components/Helper/Config';

// ----------------------------------------------------------------------

console.log('shege')

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
      </TableRow>
      
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
  }).isRequired,
};





export default function DashboardApp() {
  const user = JSON.parse(sessionStorage.getItem('pinspayFetchUsers'))
  const record = JSON.parse(sessionStorage.getItem('pinspay_record'))

  const handleOpen = () => setOpen(true);
  const [checkButton, setCheckButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [spin, setSpin] = React.useState('');
  const [isGoing, setIsGoing] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [colorMe, setColorMe] = React.useState('red');
  // const handleClose = () => setOpen(false);

  let boolg = ''
  try {
    const unclaimed = sessionStorage.getItem('pinspayUnclaimed')
    boolg = JSON.parse(unclaimed.toLowerCase());
  }
  catch(err) {
    console.log(err.message);
  }

  let amount = ''
  let name = ''
  let sentdate = ''
  let unclaim = sessionStorage.getItem('pinspayUnclaimed')
  if (unclaim == 'true' || unclaim == 'false') {
    console.log(unclaim)
      unclaim = JSON.parse(unclaim)
      if (unclaim == true) {
        console.log('amadeenio')
        const unclaimedData = JSON.parse(sessionStorage.getItem('pinspayUnclaimedData'))
        amount = unclaimedData.amount
        name = unclaimedData.sender
        sentdate = unclaimedData.sentdate
      }
  }

  const rows = [
    createData('Sender:', name),
    createData('Amount:', '₦'+amount),
    createData('Date:', sentdate),
  ];

  // let boolg = true

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      // alert('hju')
      // setOpen(false)
      sessionStorage.setItem('pinspayUnclaimed', false)
      window.location.href= '/dashboard';
      boolg = false
    }
  }

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    setSpin(nonNeg)
  }

  async function withdrawMoney() {
    if(spin == '') {
      // alert('dhdgdgdh')
      setMessage('Please enter the four digits withdrawal Pin');
      return;
    }
    setIsGoing(true);
    let pin = CryptoJS.AES.encrypt(spin, 'secret key kpngz@!1234').toString();

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      pin: pin
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/pendingtransactions', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if(response.status == 200){
          setMessage(response.message);
          setColorMe('green');

          boolg = false
          Swal.fire({
            title: 'Success!',
            text: response.message,
            icon: 'success',
            confirmButtonText: 'Ok'
          })
        } else if(response.status == 900) {

        } else if(response.status == 800) {
          setIsGoing(false);
          setMessage(response.message);
          return false;
        } else {
          setIsGoing(false);
          setMessage(response.message);
          return false;
          
        }
        // return response;
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        setMessage(msg);
    })
    return pp;
  }
  
  
  // console.log(user)

  function idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well      
    window.ontouchmove = resetTimer;  // required by some devices 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;   
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function logout() {
      sessionStorage.removeItem("pinspayFetchUsers");
      sessionStorage.removeItem("pinspay_record");
      sessionStorage.removeItem("pinspayLoginToken");
      sessionStorage.removeItem("pinspaySuccessfulTransaction");
      window.location.href= '/phone';
    }
    
    function resetTimer() {
      // console.log('ret')
      clearTimeout(t);
      t = setTimeout(logout, 120000);  // time is in milliseconds
    }
}
idleLogout();

  // console.log(record)
  const accountBal = user.AvailableBalance
  const [accountBalance, setAccountBalance] = useState(accountBal);
  global.availableBalance = accountBal;

  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  const mobilePhones = userDetails.mobilePhone
  // const mobilePhones = localStorage.getItem('pinspayNo');
  const loginToken = sessionStorage.getItem('pinspayLoginToken');



  // const mobilePhones = global.mobilePhone;
  //const check = callOut(mobilePhones);
  //const fetchUserDet = fetchUser();

  return (
    <Page title="Dashboard | Pinspay">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWeeklySales accountBalance={accountBalance} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppItemOrders />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
        {/* <Button onClick={handleOpen} id='clickMe'>Open modal</Button> */}
        

        <Modal
            open={boolg}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{borderRadius: 12}}>
                
                <Typography id="modal-modal-title" variant="h6" component="h1" align="center">
                  You have a pending withdrawal with details below:
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h4" align="center" style={{color: colorMe, paddingTop: 10, paddingBottom:10}}>
                  {message}
                </Typography>
                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    Sender: <span style={{fontWeight:900}}>Daniel Jane</span>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    Amount: <span style={{fontWeight:900}}>N5,000</span>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    Date: <span style={{fontWeight:900}}>23/4/2022</span>
                </Typography> */}
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    
                    <TableBody>
                      {rows.map((row) => (
                        <Row key={row.name} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  inputProps={{ maxLength: 4 }}
                  onInput={checkPin}
                  label="Withdrawal Pin"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{textAlign:'right', marginTop:20}}
                  disabled={(isGoing) ? true : false}
                  onClick={withdrawMoney}
                >
                  Withdraw to Wallet
                </LoadingButton>
                
                <a className="MuiTypography-button" onClick={handleClose}>Cancel</a>
            </Box>
        </Modal>
      </Container>
    </Page>
  );
}
