import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CryptoJS from 'crypto-js';
import Logo from '../../Logo';
import Config from '../../Helper/Config';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
  } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { split } from 'lodash';
import { date } from 'yup';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(trans_id, amount, channel, c_d, desc, trans_date, balance) {
  return { trans_id, amount, channel, c_d, desc, trans_date, balance };
}

const humanDate=(udate)=>{
    var d = new Date(udate)
    return d.toUTCString();
}

// var tr = new Date();
// var tr = new Date("3/7/2022 2:39:33 PM")
// console.log(humanDate(tr))

export default function Statement() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowss, setRowss] = useState([]);
  const [loading, setLoading] = useState('Loading...');
  const [checkButton, setCheckButton] = useState(false);
  const [open, setOpen] = useState(false);

  const [amount, setAmount] = useState(0);
  const [bank, setBank] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [credit_account, setCreditAccount] = useState('');
  const [debit_account, setDebitAccount] = useState('');
  const [description, setDescription] = useState('');
  const [transfer_date, setTransferDate] = useState('');
  const [message, setMessage] = useState('');
  const [transfer_type, setTransferType] = useState('');

  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  // const mobilePhone = userDetails.mobilePhone
  const defaultMobile = userDetails.mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAmount(0)
      setBank('')
      setBeneficiary('')
      setCreditAccount('')
      setDebitAccount('')
      setDescription('')
      setMessage('')
      setTransferDate('')
      setTransferType('')
      setOpen(false)
    }
  }

    function formatMyDate(idate){
        console.log(idate)
        var dd = String(idate.getDate()).padStart(2, '0');
        var mm = String(idate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = idate.getFullYear();
        idate = mm + '/' + dd + '/' + yyyy;
        return idate;
    }





  const rows = rowss.sort((a, b) => (a.TranID < b.TranID ? 1 : -1));



  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };

  const fetchTransactions1 = async () => {
    setLoading('Loading...')
    
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      mobilePhone: defaultMobile
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const res = await fetch(Config.base_url+"/unclaimedtransfer", {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'pwatoken': loginToken,
          'channel':'WEB'
        },
        body: JSON.stringify({
          rqt: cipherRxq,
        channel: 'WEB'
        })
    })

    .then((response) => response.json())
    .then((response) => {
      if(response.status == '200'){
        console.log(response)
        
        if (response.data.length > 0) {
          setLoading('')
        } else {
          setLoading('No Data')
        }
        setRowss(response.data)
        console.log(rowss)
      } else if(response.status == '900'){
        // setOpen(false)
        Swal.fire({
          title: 'Error!',
          text: response.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: { defaultMobile } });
      } else {
        console.log(response)
        setLoading('No data')
        setRowss([])
        // console.log(rowss)
      }
    })
    .catch(function(err) {
      console.log(err);
      // alert("Technical error. Please again later");
    })
    
    // const response = await res.json();
    // console.log(response.data);
    // localStorage.setItem('pinspay_banks', JSON.stringify(response.data));

  }


  useEffect(() => {
    // setDateValue([start_d, end_d])
    
      
    const fetchTransactions = async () => {
    //   console.log(loginToken);

      // console.log(start_date, end_date)
      setLoading('Loading...')
      var rxq = localStorage.getItem('rxq');
    
      var req = JSON.stringify({
        mobilePhone: defaultMobile
      })

      var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

      const res = await fetch(Config.base_url+"/unclaimedtransfer", {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'pwatoken': loginToken,
            'channel':'WEB'
          },
          body: JSON.stringify({
            rqt: cipherRxq,
            channel: 'WEB'
          })
      })
  
      .then((response) => response.json())
      .then((response) => {
        if(response.status == '200'){
          console.log(response)
          if (response.data.length > 0) {
            setLoading('')
          } else {
            setLoading('No Data')
          }
          setRowss(response.data)
          console.log(rowss)
        } else if(response.status == '900'){
          // setOpen(false)
          Swal.fire({
            title: 'Error!',
            text: response.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          logOut()
          navigate('/phone', { state: { defaultMobile } });
        } else {
          console.log(response)
          setLoading('No Data')
          setRowss([])
          // console.log(rowss)
        }
        
      })
      .catch(function(err) {
        console.log(err);
        setRowss([])
        // alert("Technical error. Please again later");
      })
    }
    
    fetchTransactions()
  },[])

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Unclaimed Transfer
          </Typography>
         
          
        </Stack>
       
        
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
            <TableRow>
                <TableCell>Trans ID</TableCell>
                <TableCell >Receiver</TableCell>
                <TableCell >Amount</TableCell>
                <TableCell >Language</TableCell>
                <TableCell >Withdrawal Pin</TableCell>
                <TableCell >Trans Date</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
            ).map((row) => (
                <TableRow key={row.TranID}>
                  <TableCell component="th" scope="row" style={{ width: 160 }}>
                      {row.trfCode}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.receiverPhone}
                  </TableCell>
                  <TableCell style={{ width: 160 }}>
                      {row.amount}
                  </TableCell>
                  
                  <TableCell style={{ width: 160 }}>
                      {row.language}
                  </TableCell>
                  <TableCell style={{ width: 160 }} >
                      {/* {row.Description} */}
                  </TableCell>
                  
                  <TableCell style={{ width: 160 }}>
                      {humanDate(row.created_at)}
                  </TableCell>
                </TableRow>
            ))}

            {emptyRows == 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8}><Typography style={{textAlign:'center', flexGrow:1}}>{loading}</Typography></TableCell>
                </TableRow>
            )}
            </TableBody>
            <TableFooter>
            <TableRow>
                <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={7}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {
                    'aria-label': 'rows per page',
                    },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                />
            </TableRow>
            </TableFooter>
        </Table>
        </TableContainer>

    </>
  );
}
