import * as Yup from 'yup';
import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Modal from '@mui/material/Modal';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Button from '@mui/material/Button';
// material
import {
  ListSubheader,
  TextField,
  InputAdornment,
  Stack, IconButton, Input
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
import FileUpload from 'react-material-file-upload';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  console.log(containsText);

export default function TierTwo() {
  const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [serverMsg, setServerMsg] = React.useState('none');
  const [transactionStatus, setTransactionStatus] = React.useState('Pending');
  const [currentCount, setCount] = useState(100);
  const [transferReference, setTransferReference] = React.useState('');
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }



  const [idCard, setFiles] = useState([]);

  useEffect(()=>{
		if (kyc_level >= 2) {
      // alert('open')
      setOpen(true)
      setServerMsg('You are already on Tier '+kyc_level+'. You are not permitted to visit this page')
    }

    if (kyc_status == 2) {
      // alert('open')
      setOpen(true)
      setServerMsg('You have already submitted request for this upgrade, and it awaits approval')
    }
	}, [])


  let scopen = sessionStorage.getItem('pinspayFetchUsers');
  let defBal = 0;
  if(scopen != null){
    scopen = JSON.parse(scopen);
    defBal = scopen.AvailableBalance
  }

  const [accountBalance, setAccountBalance] = useState(defBal);
  // console.log(defBal);

  let ric = 100;


  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));
  const [kyc_level, SetKyc_level] = React.useState(userDetails.kyc_level);
  const [kyc_status, SetKyc_status] = React.useState(userDetails.kyc_status);
  const mobilePhone = userDetails.mobilePhone

  async function callOut(mobilePhones, code, filesToUploa) {
    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      mobilePhone: mobilePhones,
      code: code,
      identityCard: filesToUploa
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();
    console.log(cipherRxq)

    var data = new FormData()
    data.append('identityCard', idCard[0])
    data.append('mobilePhone', mobilePhones)
    data.append('bvn', code)

    const pp = await fetch(Config.base_url+'/tiertwo', {
      method: 'POST',
      headers: {
        // 'Content-type': 'multipart/form-data',
        'channel':'WEB'
      },
      body: data
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        return response;
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    })
    return pp;
  }

  const RegisterSchema = Yup.object().shape({
    bvn: Yup.string().min(11, 'Too Short. 11 digits required!').max(11, 'Too Long. 11 digits required!').required('BVN is required')
  });

  const defaultMobile = mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  let myInterval = '';
  let referenceP = ''

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const checkBvn = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
  }

  const formik = useFormik({
    initialValues: {
      bvn: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      const bvn = values.bvn;
      const mobilePhones = defaultMobile;
      const check = await callOut(mobilePhones, bvn, idCard);

      if (check.status === '200') {
        Swal.fire({
          title: 'Success!',
          text: check.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
        .then(() => {
          logOut()
          navigate('/phone', { state: '' });
          
        });
        logOut()
          navigate('/phone', { state: '' });
      }else if (check.status === '900'){
        // alert(response.message);
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        logOut()
        navigate('/phone', { state: '' });
      } else {
        // alert();
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };


  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  


  return (
    <>
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            

                <TextField
                fullWidth
                // autoComplete="Phone"
                value={defaultMobile}
                disabled={true}
                // {...(isPhone ? 'disabled="true"' : 'disabled="true"')}
                label="Phone Number"
                />

                <TextField
                fullWidth
                type='text'
                label="BVN"
                onInput={checkBvn}
                {...getFieldProps('bvn')}
                style={{marginTop:15, marginBottom:15}}
                
                error={Boolean(touched.bvn && errors.bvn)}
                helperText={touched.bvn && errors.bvn}
                />

                <small style={{color:'#00AB55'}}>Upload your ID Card below</small>
                <FileUpload label="ID Card" value={idCard} onChange={setFiles} />
              
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                style={{marginTop:15}}
                variant="contained"
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            
        </Form>
        </FormikProvider>

        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{borderRadius: 12}}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
                    {serverMsg}
                </Typography>
                <Typography id="modal-modal-title" variant="h2" component="h1" align="center">
                   
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center" style={{fontWeight:400}}>
                    
                </Typography>
               
                
                <RouterLink className="MuiTypography-button" to="/dashboard">Dashboard</RouterLink>
            </Box>
        </Modal>
    </>
  );
}
