import * as Yup from 'yup';
import * as React from 'react';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import jQuery from 'jquery'
// material
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import CryptoJS from 'crypto-js';
import Config from '../../Helper/Config';

import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Profile() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  // const [language, setLang] = useState('');

  const handleChange = (event) => {
    setLang(event.target.value);
    console.log(event.target.value);
  };

  const handleGender = (event) => {
    setGender(event.target.value);
    console.log(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      // alert('hju')
      setOpen(false)
    }
  }

  const userDetails = JSON.parse(sessionStorage.getItem('pinspayFetchUsers'));
  const pay_record = JSON.parse(sessionStorage.getItem('pinspay_record'));

  const [dobs, setDob] = useState(userDetails.DoB);


  // console.log(userDetails);
  // console.log(pay_record.bvn_status);
  const bv = pay_record.bvn_status;
  const emailS = (pay_record.email == '') ? '0' : '1'
  const fEmail = pay_record.email
  console.log(emailS)
  const langS = (pay_record.lang == '') ? '0' : '1'

  const [bvnStatus, setBvnStatus] = React.useState(bv);
  const [emailStatus, setEmailStatus] = React.useState(emailS);
  const [langStatus, setLangStatus] = React.useState(langS);
  const [bvn, setBvn] = React.useState('');
  const [email, setEmail] = React.useState(fEmail);
  const [pin, setPin] = React.useState('');
  const [gender, setGender] = React.useState(pay_record.gender);
  const [language, setLang] = React.useState(pay_record.language);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const defaultMobile = pay_record.mobilePhone
  // console.log(userDetails)
  const loginToken = sessionStorage.getItem('pinspayLoginToken');

  const [colorMe, setColorMe] = React.useState('red');

  const unclaimed = sessionStorage.getItem('pinspayUnclaimed')
  const boolg = JSON.parse(unclaimed.toLowerCase());

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address'),
    pin: Yup.string().min(3, 'Too Short!').max(5, 'Too Long!').required('Transaction Pin required'),
    c_pin: Yup.string().oneOf([Yup.ref('pin'), null], 'Pin must match'),
    bvn: Yup.string().min(11, 'Too Short!').max(11, 'Too Long!'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(15, 'Too Long!')
      .required('Password is required'),
    c_password: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match')
  });

  const checkPin = (event) => {
    let nonNeg = event.target.value

    nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    setPin(nonNeg);
  }

  // Logout Function
  const logOut = () => {
    global.isLogin = false;
    global.loginToken = '';
    sessionStorage.removeItem("pinspayFetchUsers");
    sessionStorage.removeItem("pinspay_record");
    sessionStorage.removeItem("pinspayLoginToken");
    sessionStorage.removeItem("pinspaySuccessfulTransaction");
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };

  function insertPin() {
    if(bvn.length != 11) {
      Swal.fire({
        title: 'Error!',
        text: 'BVN must be 11 digits',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    }
    setOpen(true);
  }

  async function updateEmail(){
    // var cypPin = CryptoJS.AES.encrypt(pin, 'secret key kpngz@!1234').toString();
    setEmailStatus('1');
    setIsLoading(true)
    // var email = $('#email').val()

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      email: email,
      m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
    })
    

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/update-email', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
    .then((response) => response.json())
      .then((response) => {
          if (response.status === '900') {
              // alert(response.message);
              Swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
              logOut()
              navigate('/phone', { state: { defaultMobile } });
          } else if(response.status === '200') {
            setIsLoading(false)
            sessionStorage.setItem('pinspay_record', JSON.stringify(response.data));
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((result) => {
              navigate('/dashboard/account', { state: { defaultMobile } });
            })
            return pp;
          } else {
            setEmailStatus('0');
            setIsLoading(false)
            // setOpen(false);
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          }
          
      });
      // setCheckButton(false);
    return pp;
    
    
  }

  async function updateLang(){
    // var cypPin = CryptoJS.AES.encrypt(pin, 'secret key kpngz@!1234').toString();
    setLangStatus('1');
    setIsLoading(true)
    // var email = $('#lang').val()

    var rxq = localStorage.getItem('rxq');
    
    var req = JSON.stringify({
      language: language,
      m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/updatelang', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
    .then((response) => response.json())
      .then((response) => {
          if (response.status === '900') {
              // alert(response.message);
              Swal.fire({
                title: 'Error!',
                text: response.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
              logOut()
              navigate('/phone', { state: { defaultMobile } });
          } else if(response.status === '200') {
            setIsLoading(false)
            sessionStorage.setItem('pinspay_record', JSON.stringify(response.data));
            Swal.fire({
              title: 'Success!',
              text: response.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then((result) => {
              navigate('/dashboard/account', { state: { defaultMobile } });
            })
            return pp;
          } else {
            setLangStatus('0');
            setIsLoading(false)
            // setOpen(false);
            Swal.fire({
              title: 'Error!',
              text: response.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          }
          
      });
      // setCheckButton(false);
    return pp;
    
    
  }

  // async function updateBvn() {
  //   var cypPin = CryptoJS.AES.encrypt(pin, 'secret key kpngz@!1234').toString();
  //   setBvnStatus('1');
  //   setIsLoading(true)
  //   const pp = await fetch(Config.base_url+'/updatebvn', {
  //     method: 'POST',
  //     headers: {
  //       'Content-type': 'application/json',
  //       'pwatoken': loginToken,
  //       'channel':'WEB'
  //     },
  //     body: JSON.stringify({
  //       bvn: bvn,
  //       pin: cypPin,
  //       mobilePhone: defaultMobile,
  //       m_token: '$2y$10$FYlZe7HA6mi1OJkTB9v6tOev4g1t8OFt6HCOZaomQaX.brHpgOuLK'
  //     })
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //         if (response.status === '900') {
  //             // alert(response.message);
  //             Swal.fire({
  //               title: 'Error!',
  //               text: response.message,
  //               icon: 'error',
  //               confirmButtonText: 'Ok'
  //             })
  //             logOut()
  //             navigate('/phone', { state: { defaultMobile } });
  //         } else if(response.status === '200') {
  //           setIsLoading(false)
  //           sessionStorage.setItem('pinspay_record', JSON.stringify(response.data));
  //           Swal.fire({
  //             title: 'Success!',
  //             text: response.message,
  //             icon: 'success',
  //             confirmButtonText: 'Ok'
  //           })
  //           .then((result) => {
  //             navigate('/dashboard/account', { state: { defaultMobile } });
  //           })
  //           return pp;
  //         } else {
  //           setBvnStatus('0');
  //           setOpen(false);
  //           setIsLoading(false)
  //           Swal.fire({
  //             title: 'Error!',
  //             text: response.message,
  //             icon: 'error',
  //             confirmButtonText: 'Ok'
  //           })
  //         }
          
  //     });
  //     // setCheckButton(false);
  //   return pp;
  // }

  // const checkBvn = (event) => {
  //   let nonNeg = event.target.value

  //   nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
  //   event.target.value = nonNeg;
  //   setBvn(nonNeg)
  // }

  const checkEmail = (event) => {
    let nonNeg = event.target.value

    // nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    setEmail(nonNeg)
  }

  const checkLang = (event) => {
    let nonNeg = event.target.value

    // nonNeg = nonNeg.replace(/[A-Za-z]/g, "") // Remove alphabets
    event.target.value = nonNeg;
    setLang(nonNeg)
  }

  const { state } = useLocation();

  const realMobile = defaultMobile;

  const formik = useFormik({
    initialValues: {
      firstName: userDetails.FirstName,
      lastName: userDetails.LastName,
      email: userDetails.email,
      pin: '',
      c_pin: '',
      bvn: userDetails.bvn,
      password: '',
      c_password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      const realDate = Intl.DateTimeFormat('en-UK', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(dobs);
      console.log(values);
      const allValues = { ...values, language, dob: realDate, mobilePhone: realMobile, gender };
      const passwordArray = {
        password: values.password,
        c_password: values.c_password,
        pin: values.pin,
        mobilePhone: defaultMobile
      };
      console.log(allValues);
      sessionStorage.setItem('pinspayCreatUser', JSON.stringify(allValues));
      sessionStorage.setItem('pinspayPwd', JSON.stringify(passwordArray));
      navigate('/verifyphone', { state: { realMobile, url: 'register' } });
      // navigate('/dashboard', { replace: true });
    }
  });

  // const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const [dValue, setdValue] = useState(null);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={7}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                disabled
                label="First name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                disabled
                label="Last name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                style={{width: '80%'}} 
                disabled={(emailStatus === '1') ? true : false} 
                autoComplete="email"
                defaultValue={fEmail}
                onInput={checkEmail} 
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <Button
                size="large"
                disabled={(emailStatus === '1') ? true : false} 
                variant="contained"
                onClick={updateEmail}
                loading={isLoading}
              >
                Update Email
              </Button>
            </Stack>
              

            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField 
                disabled={(bvnStatus === '0') ? true : false} 
                onInput={checkBvn} 
                inputProps={{ maxLength: 11 }} 
                label="BVN" 
                style={{width: '80%'}} 
              />

              <LoadingButton
                size="large"
                disabled={(bvnStatus === '0') ? true : false}
                variant="contained"
                onClick={insertPin}
                loading={isLoading}
              >
                Update BVN
              </LoadingButton>
            </Stack> */}

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} style={{width: '80%'}} >
                <InputLabel id="demo-simple-select-helper-label">Language</InputLabel>
                <Select
                  name="language"
                  disabled={(langStatus === '0') ? true : false} 
                  fullWidth
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={language}
                  label="Language"
                  onChange={checkLang}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Yoruba">Yoruba</MenuItem>
                  <MenuItem value="Igbo">Igbo</MenuItem>
                  <MenuItem value="Hausa">Hausa</MenuItem>
                  <MenuItem value="Pidgin">Pidgin</MenuItem>
                </Select>
                {/* <FormHelperText>i</FormHelperText> */}
              </FormControl>
              <LoadingButton
                size="large"
                disabled={(langStatus === '0') ? true : false}
                variant="contained"
                onClick={updateLang}
                loading={isLoading}
              >
                Update Language
              </LoadingButton>
            </Stack>

            <TextField fullWidth label="Phone Number" disabled value={defaultMobile} />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} style={{width: '60%'}}>
                <InputLabel id="demo-simple-select-helper-label">Gender</InputLabel>
                <Select
                  name="gender"
                  disabled
                  fullWidth
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  onChange={handleGender}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                {/* <FormHelperText>i</FormHelperText> */}
              </FormControl>


              <LocalizationProvider dateAdapter={DateAdapter} fullWidth>
                <DatePicker
                  fullWidth
                  label="Date of Birth"
                  value={dobs}
                  disabled
                  onChange={(newValue) => {
                    setDob(newValue);
                    console.log(newValue);
                  }}
                  // {...getFieldProps('dob')}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>

            
          </Stack>
        </Form>
      </FormikProvider>

      
    </>
  );
}
