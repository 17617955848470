import * as Yup from 'yup';
import React, { Component }  from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Config from '../../Helper/Config';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CryptoJS from 'crypto-js';

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    pin: Yup.string().required('Pin is required'),
    npin: Yup.string().min(4, 'Too Short!').max(4, 'Too Long!').required('New Transaction Pin required'),
    c_npin: Yup.string().oneOf([Yup.ref('npin'), null], 'Pin must match')
  });

  const userDetails = JSON.parse(sessionStorage.getItem('pinspay_record'));

  const defaultMobile = userDetails.mobilePhone;
  const loginToken = sessionStorage.getItem('pinspayLoginToken');
  const realMobile = defaultMobile;
  // console.log(defaultMobile);

  async function changePin(values) {
      console.log(values);
    var gpin = CryptoJS.AES.encrypt(values.pin, 'secret key kpngz@!1234').toString();
    var gpinnew = CryptoJS.AES.encrypt(values.npin, 'secret key kpngz@!1234').toString();

    var rxq = localStorage.getItem('rxq');
    var req = JSON.stringify({
      pin: gpin,
      newPin: gpinnew,
    })

    var cipherRxq = CryptoJS.AES.encrypt(req, rxq).toString();

    const pp = await fetch(Config.base_url+'/changepin', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'pwatoken': loginToken,
        'channel':'WEB'
      },
      body: JSON.stringify({
        rqt: cipherRxq,
        channel: 'WEB'
      })
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch(function(err) {
        console.log(err);
        var msg = "Technical error. Please again later";
        Swal.fire({
          title: 'Error!',
          text: msg,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        
    })
    return pp;
  }

  const formik = useFormik({
    initialValues: {
      npin: '',
      c_npin: '',
      pin: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      console.log(values);
      const check = await changePin(values);
      if (check.status === '200') {
        // const allValues = { ...values, mobilePhone: defaultMobile };
        // alert('Pin changed Successfully';
        Swal.fire({
          title: 'Successful',
          text: check.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else if (check.status === '900'){
        // alert(check.message);
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        sessionStorage.removeItem("pinspayFetchUsers");
        sessionStorage.removeItem("pinspay_record");
        sessionStorage.removeItem("pinspayLoginToken");
        sessionStorage.removeItem("pinspaySuccessfulTransaction");
        navigate('/phone', { state: { realMobile } });
      } else {
        Swal.fire({
          title: 'Error!',
          text: check.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  });

  const phoneNumber = global.mobilePhone;
  const isPhone = true;
  // alert(phoneNumber);
  if (phoneNumber === '' || phoneNumber === null) {
    // isPhone = false;
    // navigate('/phone', { replace: true });
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  // console.log(defaultMobile);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label="Pin"
            {...getFieldProps('pin')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.pin && errors.pin)}
            helperText={touched.pin && errors.pin}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New Pin"
            {...getFieldProps('npin')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.npin && errors.npin)}
            helperText={touched.npin && errors.npin}
          />

            <TextField
                fullWidth
                autoComplete="password"
                type={showPassword ? 'text' : 'password'}
                label="Confirm New Pin"
                {...getFieldProps('c_npin')}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                    </InputAdornment>
                )
                }}
                error={Boolean(touched.c_npin && errors.c_npin)}
                helperText={touched.c_npin && errors.c_npin}
            />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Continue
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
